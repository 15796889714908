import React, { useEffect } from 'react';
import { isNews } from '../../utils/isNews';

export function SubbuzzInsert({
  buzz,
  subbuzzEl,
}) {

  // Loop through each subbuzz
  useEffect(() => {
    if (!subbuzzEl.current) {
      return;
    }
    // temporary solution for styling product links for buzzfeed_news destination
    if (isNews(buzz) && buzz.tags.includes('comtent')) {
      subbuzzEl.current.querySelectorAll('a[href]').forEach(link => {
        if (getComputedStyle(link).fontStyle === 'italic' || link?.firstElementChild?.tagName === 'I') {
          link.classList.add('js-news-product');
        }
      });
    }

  }, [subbuzzEl]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div />;
}

export default SubbuzzInsert;
