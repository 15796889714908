import { CaretRightIcon, CaretLeftIcon } from '@buzzfeed/react-components';
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import { trackClientContentAction } from '../../hooks/analytics/client-event-tracking';
import styles from './relatedProducts.module.scss';
import ProductCard from './ProductCard';
import crystalBall from './crystal_ball.svg';
import { useRelatedProducts } from '../../hooks/useRelatedProducts';

const GRID = 'product_grid';
const CAROUSEL = 'product_carousel';

export const RelatedProducts = ({
  buzz,
  parentSubbuzzIndex,
  parentSubbuzzId,
  subbuzzEl,
  variant,
}) => {
  const ref = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);
  const [scrollWidth, setScrollWidth] = useState();
  const [productsToShow, setProductsToShow] = useState(0);
  const [placeholderEl, setPlaceholderEl] = useState(false);
  const isProductSubbuzz = !!subbuzzEl?.current?.querySelector(
    '[data-vars-product-id]'
  );
  const hasRelatedProductsLasertag =
    buzz?.laser_tags?.bf_content_description?.workflow?.some((tag) => {
      return tag.tag_name === 'enable_related_products_unit';
    });
  const products = useRelatedProducts({
    buzz,
    hasRelatedProductsLasertag,
    parentSubbuzzId,
  });

  // Add parent div into dom
  useEffect(() => {
    if (!subbuzzEl.current || placeholderEl) {
      return;
    }

    const relatedProductsWrapper = document.createElement('div');
    relatedProductsWrapper.classList.add('relatedProductsPlaceholder');

    let sb = subbuzzEl.current.querySelector('.subbuzz') ?? false;

    if (sb) {
      sb = sb.querySelector('.subbuzz') ? sb.querySelector('.subbuzz') : sb;
      sb.appendChild(relatedProductsWrapper);
      setPlaceholderEl(relatedProductsWrapper);
    }
  }, [subbuzzEl, placeholderEl]);

  let filteredProducts = products.slice(0, 10);

  const trackingLayer = useMemo(
    () => ({
      page_edition: 'en-us',
      destination: 'buzzfeed',
      context_page_type: 'buzz',
      context_page_id: buzz.id,
      unit_type: 'buzz_body',
      unit_name: buzz.id,
      subunit_type: 'subbuzz',
      subunit_name: `related_products_${
        variant === GRID ? 'grid' : 'carousel'
      }_${parentSubbuzzId}`,
    }),
    [buzz, variant, parentSubbuzzId]
  );

  const arrowTracking = useMemo(
    () => ({
      ...trackingLayer,
      item_type: 'button',
      item_name: 'arrow',
      position_in_unit: parentSubbuzzIndex,
      action_type: 'navigate',
    }),
    [parentSubbuzzIndex, trackingLayer]
  );

  const buttonTracking = useMemo(
    () => ({
      ...trackingLayer,
      item_type: 'button',
      item_name: 'see_more',
      position_in_unit: parentSubbuzzIndex,
      action_type: 'show',
      action_value: `${filteredProducts.length - productsToShow}_more`,
    }),
    [trackingLayer, parentSubbuzzIndex, filteredProducts.length, productsToShow]
  );

  const isTwoPartSubbuzz =
    buzz.sub_buzzes[parentSubbuzzIndex] &&
    buzz.sub_buzzes[parentSubbuzzIndex].number !==
      buzz.sub_buzzes.length.toString() &&
    buzz.sub_buzzes[parentSubbuzzIndex + 1] &&
    !buzz.sub_buzzes[parentSubbuzzIndex + 1].number;

  useEffect(() => {
    const element = ref.current;

    if (!element || variant === GRID) {
      return;
    }

    setScrollWidth(ref.current.scrollWidth);
  }, [variant, showLeftButton, showRightButton, ref]);

  useEffect(() => {
    setProductsToShow(variant === GRID ? 4 : filteredProducts.length);
  }, [variant, filteredProducts.length]);

  useEffect(() => {
    if (variant === CAROUSEL) {
      if (scrollValue <= 0) {
        setShowLeftButton(false);
      } else {
        setShowLeftButton(true);
      }

      if (scrollValue === 0 || scrollValue < scrollWidth - 538) {
        setShowRightButton(true);
      } else {
        setShowRightButton(false);
      }
    }
  }, [scrollValue, scrollWidth, variant]);

  const scroll = (scrollOffset) => {
    const element = ref.current;
    if (!element) {
      return;
    }

    setScrollValue((ref.current.scrollLeft += scrollOffset));
  };

  const handleSeeMoreClick = useCallback(() => {
    setProductsToShow(filteredProducts.length);
    trackClientContentAction({}, buttonTracking);
  }, [buttonTracking, filteredProducts.length]);

  const handleScrollClick = useCallback(
    (direction) => {
      if (direction === 'right') {
        scroll(528);
      } else if (direction === 'left') {
        scroll(-528);
      }

      trackClientContentAction(
        {},
        {
          ...arrowTracking,
          action_value: direction,
        }
      );
    },
    [arrowTracking]
  );

  useEffect(() => {
    if (
      !placeholderEl ||
      !isProductSubbuzz ||
      productsToShow === 0 ||
      isTwoPartSubbuzz ||
      filteredProducts.length < 4
    ) {
      return;
    }
    ReactDOM.render(
      <>
        {(variant === GRID || variant === CAROUSEL) && (
          <>
            <div
              className={
                variant === GRID
                  ? styles.gridContainer
                  : styles.carouselContainer
              }
            >
              <div
                className={
                  variant === GRID
                    ? styles.gridHeaderWrapper
                    : styles.carouselHeaderWrapper
                }
              >
                <div className={styles.titleWrapper}>
                  <div className={styles.title}> More recommendations </div>
                  <div className={styles.subtitle}>
                    We’re testing something new with artificial intelligence so
                    products may not always be relevant.
                  </div>
                </div>
                <img src={crystalBall} alt="" className={styles.headerImg} />
              </div>
              <div
                ref={ref}
                className={
                  variant === GRID ? styles.gridWrapper : styles.carouselWrapper
                }
              >
                {filteredProducts
                  .slice(0, productsToShow)
                  .map((item, index) => (
                    <ProductCard
                      item={item}
                      index={index}
                      variant={variant}
                      buzz={buzz}
                      trackingLayer={trackingLayer}
                      parentSubbuzzIndex={parentSubbuzzIndex}
                      key={index}
                    />
                  ))}
                {showLeftButton && (
                  <button
                    onClick={() => handleScrollClick('left')}
                    className={`${styles.scrollButton} ${styles.scrollButtonLeft}`}
                  >
                    <CaretLeftIcon className={styles.icon} />
                  </button>
                )}
                {showRightButton && (
                  <button
                    onClick={() => handleScrollClick('right')}
                    className={`${styles.scrollButton} ${styles.scrollButtonRight}`}
                  >
                    <CaretRightIcon className={styles.icon} />
                  </button>
                )}
              </div>

              {variant === GRID &&
                productsToShow !== filteredProducts.length &&
                productsToShow !== 0 &&
                filteredProducts.length > 4 && (
                  <div className={styles.seeMoreButtonWrapper}>
                    <button
                      className={styles.seeMoreButton}
                      onClick={handleSeeMoreClick}
                    >
                      See {filteredProducts.length - productsToShow} more
                    </button>
                  </div>
                )}
            </div>
            <div className={styles.feedbackWrapper}>
              <span> Have thoughts on this unit?</span>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSf5D1hohLfrGtwLP5nhS6lqPoVtZ7YydJhpncqwxlMAvllUnQ/viewform"
                className={styles.feedbackLink}
                target="_blank"
                rel="noreferrer"
              >
                Submit your feedback.
              </a>
            </div>
          </>
        )}
      </>,
      placeholderEl
    );
  }, [
    buzz,
    filteredProducts,
    handleScrollClick,
    handleSeeMoreClick,
    isProductSubbuzz,
    isTwoPartSubbuzz,
    parentSubbuzzIndex,
    placeholderEl,
    productsToShow,
    showLeftButton,
    showRightButton,
    trackingLayer,
    variant,
  ]);

  return <></>;
};

export default RelatedProducts;
