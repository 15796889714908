import * as React from 'react';

function DownArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.945 9.048l-4.95 4.95-4.95-4.95L4.46 7.634 7 10.174V1.999h2v8.165l2.53-2.53 1.415 1.414z"
      />
    </svg>
  );
}

export default DownArrow;
