import { useEffect, useRef } from 'react';

export function useFloatingMode(playerApi, containerId, enableFloating) {
  const isFloating = useRef(false);

  useEffect(() => {
    if (!enableFloating) {
      playerApi?.disableFloatingMode();
      isFloating.current = false;
      return;
    }

    if (!enableFloating || !containerId.current) return;

    if (typeof playerApi.enableFloatingMode !== 'function' || typeof playerApi.disableFloatingMode !== 'function') {
      return;
    }

    if (typeof IntersectionObserver === 'undefined') {
      return;
    }

    const playerElement = containerId.current;
    if (playerElement) {
      playerElement.style.minHeight = '100px';
    }

    const observerCallback = entries => {
      entries.forEach(entry => {
        const rect = entry.boundingClientRect;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        if (!entry.isIntersecting && rect.top < 0 && !isFloating.current) {
          playerApi.enableFloatingMode();
          isFloating.current = true;
        } else if (entry.isIntersecting && rect.bottom <= viewportHeight && isFloating.current) {
          playerApi.disableFloatingMode();
          isFloating.current = false;
        }
      });
    };

    const observerOptions = { threshold: [0, 0.1], rootMargin: '0px' };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (playerElement) {
      observer.observe(playerElement);
    }

    return () => {
      if (playerElement) {
        observer.unobserve(playerElement);
      }
    };
  }, [enableFloating, playerApi, containerId]);
}
