import React from 'react';
import AdUnit from '../AdUnit';
import { sizes } from '@buzzfeed/adlib/dist/module/bindings/react';

export default function AdInlineTrending({ pixiedust }) {
  return (
    <AdUnit
      config={{
        adType: 'ex',
        adPos: 'promo-inline-trending-ad-unit-slot',
        size: [
          sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
          sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
          sizes.PROGRAMMATIC_LEADERBOARD,
          sizes.NATIVE,
          sizes.FLUID,
        ],
        viewability: 'high',
      }}
      pixiedust={pixiedust}
    />
  );
}
