const supportedLanguages = ['de', 'en', 'es', 'ja', 'pt'];

export const badgeExcludeList = ['trending', 'ridealong', 'pick', 'fail'];

export function getBadgeLanguage(countryCode) {
  if (countryCode === 'es-es') {
    return countryCode;
  }
  const [language] = (countryCode || '').split('-');

  return supportedLanguages.includes(language) ? language : 'en';
}

export function sortBadges(badges) {
  return badges.sort(function(badge1, badge2) {
    if (badge1.priority > badge2.priority) {
      return 1;
    }
    if (badge1.priority < badge2.priority) {
      return -1;
    }

    if (badge1.price > badge2.price) {
      return 1;
    }
    if (badge1.price < badge2.price) {
      return -1;
    }

    return 0;
  });
}

export function invalidType(badge, flags) {
  const showEditorialBadges = flags.sensitive === 0;
  const showSponsoredBadges =
    flags.sensitive === 0 && flags.nsfw === 0 && flags.brand_safe === 1;
  const isEditorialBadge = ['Editorial', 'Reaction'].includes(badge.category);
  const isSponsoredBadge = ['Custom', 'Thematic'].includes(badge.category);
  const missingImagePath = !badge.path;

  return (
    (!showEditorialBadges && isEditorialBadge) ||
    (!showSponsoredBadges && isSponsoredBadge) ||
    missingImagePath
  );
}
