import React, { useEffect, useContext } from 'react';
import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';
import BuzzContext from '../../contexts/buzz';
import ConsentContext from '../../contexts/consent';
import { excludedCategories } from '../../constants/affiliate';

const divId = 'amzn-assoc-ad-8e4d6210-350e-4d17-b9c5-70937cc906ff';
const instanceId = '8e4d6210-350e-4d17-b9c5-70937cc906ff';

function AmazonOneLink() {
  const buzz = useContext(BuzzContext);
  const { tracking: { consentValue, isConsentReady } } = useContext(ConsentContext);

  const isAffiliate =
    !buzz.isAd &&
    buzz.metavertical !== 'news' &&
    !buzz.flags.sensitive &&
    !buzz.flags.nsfw &&
    buzz.isUS &&
    excludedCategories.indexOf(buzz.category.toLowerCase()) !== -1;

  useEffect(() => {
    if (isAffiliate && consentValue && isConsentReady) {
      loadScript(
        '//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=' +
          instanceId
      );
    }
  }, [isAffiliate, consentValue, isConsentReady]);

  if (!isAffiliate) {
    return null;
  }

  return <div id={divId} />;
}

export default AmazonOneLink;
