import React, { useContext, useState, useEffect, useMemo } from 'react';
import composeRefs from '@seznam/compose-react-refs';
import { useAmazonSubtagModifier } from '../../hooks/useAmazonSubtagModifier';
import { useSkimlinksXcustModifier } from '../../hooks/useSkimlinksXcustModifier';
import { Products } from '@buzzfeed/react-components';
import DestinationContext from '../../contexts/destination';
import styles from './retailMediaNetwork.module.scss';
import SectionTitle from '../SectionTitle';
import { useUnitTracking } from '../../hooks/analytics/useUnitTracking';

export default function RetailMediaNetwork({ buzz = {} }) {
  const watsonCategoryTags = buzz?.laser_tags?.watson?.category || [];
  const watsonKeywordTags = buzz?.laser_tags?.watson?.keyword || [];
  const watsonTags = watsonKeywordTags.length > 0 ? watsonKeywordTags : watsonCategoryTags;
  const tags = watsonTags.map((tagDetails) => tagDetails.tag_name.replaceAll('_', ' '));
  const [products, setProducts] = useState([]);
  const { base_url } = useContext(DestinationContext);

  useEffect(() => {
    async function fetchProducts() {
      try {
        const response = await fetch(
          `${base_url}/site-component/v1/commerce-product-search?q=retailer:amazon AND ${encodeURIComponent(
            tags.join(' OR ')
          )}`
        );
        const data = await response.json();
        setProducts(data?.items?.slice(0, 4) || []);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
    if (tags.length > 0) {
      fetchProducts();
    }
  }, []);


  // Currently the products component clicks through to the buzz article.
  // This will force it click through to the product page. If this component is a success
  // we will update the Products component to be more flexible
  if (products) {
    products.forEach((product, index) => {
      delete products[index]['latest_subbuzz']['id'];
      products[index]['latest_subbuzz']['parent_buzz_canonical_url'] = products[index]['detail_page_url'];
    });
  }

  if (products.length === 0) {
    return null;
  }

  return (<RetailMediaNetworkWrapper buzz={buzz} products={products} />);
}

const RetailMediaNetworkWrapper = ({buzz, products}) => {
  const clickAmazonRef = useAmazonSubtagModifier({ buzz });
  const clickSkimlinksRef = useSkimlinksXcustModifier({ buzz });
  const unitTrackingData = useMemo(
    () => ({
      unit_type: 'buzz_bottom',
      unit_name: buzz.id,
    }),
    [buzz.id]
  );
  return (
    <div className={styles.retailProductsGridWrapper}>
      <SectionTitle id="reactions-title">{'Our Bestselling Products'}</SectionTitle>
      <div className={styles.retailProductsGrid} ref={composeRefs(clickAmazonRef, clickSkimlinksRef)}>
        <Products
          offset={0}
          len={4}
          products={products}
          useUnitTracking={useUnitTracking}
          tracking={unitTrackingData}
        />
      </div>
    </div>
  );
}
