import { useEffect, useRef, useContext } from 'react';
import {
  setElementTags,
  getElementTags,
  getElementData,
} from '@buzzfeed/bf-utils/lib/affiliate-link';
import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';
import {
  getReferrerLabel,
  getExperimentSubtags,
  getSource,
} from '@buzzfeed/react-components/lib/utils/affiliate';
import {
  excludedCategories,
  excludedEditions,
  excludedSkimlinksTags,
} from '../../constants/affiliate';
import ABeagleContext from '../../contexts/abeagle';

const _escapeStrRegex = str => {
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

/**
 * Checks for valid affiliate link and converts them
 * by adding subtags for platform, origin and experiments
 */
const modifyHREF = ({ element, experimentSubtags }) => {
  if (!element) {
    return;
  }
  var _escapedPrefix = _escapeStrRegex('https://go.redirectingat.com/');
  var _fullREStrV1 =
    _escapedPrefix + '/?\\?id=(.*)&site=(.*)&xs=(.*)&url=(http.*)';
  var _fullREStrV2 =
    _escapedPrefix + '/?\\?id=(.*)&sref=(.*)&url=(http.*)&xs=(.*)';
  var _fullREV1 = new RegExp(_fullREStrV1);
  var _fullREV2 = new RegExp(_fullREStrV2);
  var _linkId = getElementData(element)['link-id'];

  if (!_fullREV1.test(element.href) && !_fullREV2.test(element.href)) {
    return;
  }

  const _xcustArr =
    decodeURIComponent(getElementTags(element)['xcust'] || '').split('|') || '';
  _xcustArr[1] = getSource();
  _xcustArr[2] = getReferrerLabel();
  _xcustArr[3] = experimentSubtags;
  _xcustArr[4] = _linkId;
  setElementTags(element, { xcust: encodeURIComponent(_xcustArr.join('|')) });
};

export function useSkimlinksXcustModifier({ buzz = {} }) {
  const ref = useRef(null);
  const { experiments } = useContext(ABeagleContext);

  useEffect(() => {
    const element = ref.current;
    const excludeByEdition =
      excludedEditions.indexOf(buzz.classification.edition.toLowerCase()) !==
      -1;
    const excludeByCategory =
      excludedCategories.indexOf(buzz.classification.section.toLowerCase()) !==
      -1;
    const excludeBySkimlinksTags = excludedSkimlinksTags.some(r =>
      buzz.tags.includes(r)
    );
    const ukShoppingVertical =
      buzz.classification.edition.toLowerCase() === 'uk' &&
      buzz.classification.section.toLowerCase() !== 'shopping';

    if (
      !element ||
      excludeByEdition ||
      excludeByCategory ||
      excludeBySkimlinksTags ||
      buzz.isAd ||
      buzz.flags.sensitive !== 0 ||
      buzz.flags.nsfw !== 0 ||
      ukShoppingVertical
    ) {
      return;
    }

    const experimentSubtags = getExperimentSubtags(experiments);

    element.querySelectorAll('a[href]').forEach(link => {
      // only modify skimlinks once
      if (link.classList.contains('js-skimlink-subtag-modified')) {
        return;
      }
      link.classList.add('js-skimlink-subtag-modified');

      // add tracking id if it's missing
      if (!link.hasAttribute('data-skimlinks-tracking')) {
        link.setAttribute('data-skimlinks-tracking', buzz.id || '');
      }

      link.addEventListener(
        'click',
        ev => {
          modifyHREF({ element: ev.target.closest('a'), experimentSubtags });
        },
        {
          once: true,
        }
      );
    });
    // check if we already loaded the skimlinks script before loading to prevent multiple loads
    const skimScript = document.querySelector('script[src*="skimresources"]');
    if (!skimScript) {
      loadScript('https://s.skimresources.com/js/74679X1524629.skimlinks.js');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buzz.id, experiments.loaded]);

  return ref;
}
