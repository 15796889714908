import React, { useContext, useMemo, useEffect, useState } from 'react';
import styles from './footer-community.module.scss';
import { SignInModal } from '@buzzfeed/react-components';
import BuzzContext from '../../contexts/buzz';
import {
  MODERATED_COMMUNITY,
  UNMODERATED_COMMUNITY,
} from '../../constants/editorial-status';
import { withTranslation } from '../../i18n';
import { useSignInTracking } from '../../hooks/analytics/useSignInTracking';
import { useUnitTracking } from '../../hooks/analytics/useUnitTracking';
import communityPromoImg from '../../public/static/images/communityPromoBanner/quiz_mock.png';
import { getUserCookieInfo } from '../../utils/userInfo';

function FooterCommunity() {
  const buzz = useContext(BuzzContext);
  const [isUserAuth, setUserAuth] = useState(false);
  const [signInModalIsOpen, setSignInModalIsOpen] = useState(false);

  useEffect(() => {
    const userInfo = getUserCookieInfo();
    if (userInfo) {
      setUserAuth(true)
    }
  }, []);

  const communityPromoUnitTrackingData = useMemo(
    () => ({
      unit_type: 'buzz_bottom',
      unit_name: buzz.id,
      item_name: 'more_community',
      item_type: 'button',
      target_content_type: 'feed',
      target_content_id: 'community_about',
    }),
    [buzz.id]
  );

  const trackCommunityPromoClick = useUnitTracking(
    communityPromoUnitTrackingData
  );

  const communitySignupUnitTrackingData = useMemo(
    () => ({
      unit_type: 'buzz_bottom',
      unit_name: buzz.id,
      item_name: 'community_footer_sign_up',
      item_type: 'button',
      target_content_type: 'auth',
      target_content_id: 'sign_in',
    }),
    [buzz.id]
  );

  const trackCommunitySignupClick = useSignInTracking(
    buzz, 
    communitySignupUnitTrackingData
  );

  const isCommunity = [MODERATED_COMMUNITY, UNMODERATED_COMMUNITY].includes(
    buzz.editorial_status
  );
  const isPromoBanner = buzz.isEnglish && (buzz.isQuiz || isCommunity);

  return (
    <>
      {isPromoBanner && <>
        <div className={styles.footerCommunityBanner}>
          <div className={styles.flexContainer}>
            <div className={styles.flexContainerTitle}>Want to get your very own quizzes and posts featured on BuzzFeed’s homepage and app? 
              <br />
              <br />
              Become a Community Contributor.
            </div>
            <div className={styles.flexContainerImgWrapper}>
              <img
                className={styles.flexContainerImg}
                src={communityPromoImg}
                alt='promo'
              />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            {!isUserAuth &&
              <a
                className={styles.loginBtn}
                role="button"
                onClick={() => setSignInModalIsOpen(true)}
              >
                <p>Sign up to get started</p>
              </a>
            }
              <a
                className={isUserAuth ? styles.learnMoreBtn : styles.promoLink}
                target="_blank"
                href="https://www.buzzfeed.com/community/about"
                rel="noreferrer"
                ref={trackCommunityPromoClick}
              >
                Learn more about Community
              </a>
          </div>
        </div>
        <SignInModal
          isOpen={signInModalIsOpen}
          onClose={() => setSignInModalIsOpen(false)}
          track={trackCommunitySignupClick}
        />
      </>}
    </>
  );
}

export default withTranslation('common')(FooterCommunity);
