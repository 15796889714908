import { useEffect, useContext, useRef } from 'react';
import BuzzContext from '../../contexts/buzz';
import DestinationContext from '../../contexts/destination';
import {
  attachClientImpressionHandler,
  attachClientExternalLinkHandler,
  attachClientInternalLinkHandler,
} from './client-event-tracking';

export function usePackageTracking(unitData) {
  const ref = useRef(null);
  const buzz = useContext(BuzzContext);
  const { destination } = useContext(DestinationContext);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return () => {};
    }
    const data = {
      ...unitData,
      action: 'post/package/' + unitData.index,
      position_in_unit: 0,
      unit_name: 'package',
      unit_type: 'buzz_bottom',
      subunit_type: 'package',
      subunit_name: unitData.category,
      label: unitData.category,
    };

    if (unitData.index === 'cta') {
      data.action = 'cta/package';
    }

    const detachImpressionHandler = attachClientImpressionHandler(
      element,
      buzz,
      data
    );
    const detachLinkHandler = data.target_content_url
      ? attachClientExternalLinkHandler(element, buzz, data)
      : attachClientInternalLinkHandler(element, buzz, data);

    return () => {
      detachImpressionHandler();
      detachLinkHandler();
    };
  }, [buzz, destination, unitData]);

  return ref;
}
