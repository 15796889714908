import { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './featureimage.module.scss';
import BuzzContext from '../../../../../contexts/buzz';
import DestinationContext from '../../../../../contexts/destination';
import { withErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import dynamicImport from '../../../../../utils/dynamic-import';

const FeatureImage = dynamicImport(
  () => import(/* webpackChunkName: "feature-image" */ './dynamic'),
  { ssr: false }
);

function FeatureImagePlaceholder({ data = {} }) {
  // use the mobile ratio for placeholder height
  // use desktop if there is no mobile
  const { images } = data;
  const buzz = useContext(BuzzContext);
  const { destination } = useContext(DestinationContext);

  // On desktop, if post has title overlay text, <h1> will be located here instead of in main article body
  const hasImageText =
    buzz.longform_custom_header.style &&
    buzz.longform_custom_header.style.text_over;
  const hasImageShadow =
    hasImageText &&
    buzz.longform_custom_header.style.text_over.gradient &&
    buzz.longform_custom_header.style.text_over.gradient !== 'no_gradient';
  const { title, description } = buzz.longform_custom_header;

  if (!images) {
    return null;
  }

  const { width, height } = images.mobile ||
    images.standard || { width: 1, height: 1 };
  const ratio = parseInt((height / width) * 100, 10);

  return (
    <div
      className={`${styles.featureImageWrapper} ${
        styles[`featureImage-${destination}`]
      } embed-feature-image`}
    >
      <div
        style={{ paddingBottom: `${ratio}%` }}
        className={styles.featureImagePlaceholder}
      >
        <FeatureImage data={data} />
      </div>
      {hasImageText && (
        <div
          className={`${styles.featureText} ${
            hasImageShadow ? styles.featureImageShadow : ''
          }`}
        >
          <div className={styles.featureTextInner}>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

FeatureImagePlaceholder.propTypes = {
  data: PropTypes.object,
};

export default withErrorBoundary(FeatureImagePlaceholder, {
  onError: captureException,
});
