import { buzzTracker } from '../utils/localBuzzTracking';
import { objectToQueryString } from '@buzzfeed/bf-utils/lib/query-string';
import { recsys_api_origin } from '../constants';

const queryDefaults = {
  edition: 'en-us',
  page_size: 30,
  buzz_id: '',
  page: 1,
  lang: 'en',
  country: 'us',
};

export function buildRecsysQuery(
  endpoint,
  {
    pageSize: page_size,
    buzzId: buzz_id,
    // *** New uuid format is not compatible with recsys ***
    // Make sure uuid format is supported before uncommenting this
    // userId: client_id,
    language: lang,
    ...params
  }
) {
  if (!endpoint) {
    throw new Error('endpoint is required');
  }

  const filter = buzzTracker.getIds();
  const queryParams = {
    page_size,
    buzz_id,
    // *** New uuid format is not compatible with recsys ***
    // Make sure uuid format is supported before uncommenting this
    // client_id,
    lang,
    ...params,
  };

  Object.keys(queryDefaults).forEach(key => {
    if (queryParams[key] === undefined) {
      queryParams[key] = queryDefaults[key];
    }
  });

  if (filter.length && !queryParams.filter) {
    queryParams.filter = filter;
  }
  return `${recsys_api_origin}/${endpoint}${objectToQueryString(queryParams)}`;
}
