import { share } from './index';

export const shareBehavior = (buzz, context) => {
  var _eventHandlers = {};
  var _element = context.getElement();

  var _behavior = {};

  const { title, canonical_url, destination_name, shareData } = buzz;

  /**
   * Triggers sharing dialogue according to `data.network` field
   * @param {Object} data - data to be shared
   * @param {String} data.network - required. Social network to be shared with
   * @param {Event} data.event - DOM Event object which triggered sharing initially
   * @param {HTMLElement} data.element - element which was clicked initially
   * @returns {void}
   */
  function _onShare(data) {
    const subbuzzId = context.getConfig('id');
    const platform = data.network;
    delete data.network;

    if (!data.element && data.event) {
      data.element = data.event.target;
    }
    delete data.event;

    share(
      platform,
      { canonical_url, destination_name, shareData, title },
      { ...data, subbuzzId }
    );
  }

  const shareMessageKey = `share-${_element.id}`;
  _eventHandlers[shareMessageKey] = _onShare;
  _behavior.messages = [shareMessageKey];

  _behavior.onclick = function(event, element, elemType) {
    if (elemType === 'sharing-button') {
      _onShare({
        network: element.getAttribute('data-bfa-network'),
        element,
      });
    }
  };

  /**
   * Handles event subscriptions.
   * @param {String} msg - event name.
   * @param {*} data - event data.
   * @listens share-click
   * @return {void}
   */
  _behavior.onmessage = function(msg, data) {
    var handler = _eventHandlers[msg];
    if (typeof handler === 'function') {
      handler.call(this, data);
    }
  };

  return _behavior;
};
