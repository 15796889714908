import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';
import { useEffect } from 'react';

export function useAwin(buzz, AdsContext) {
  useEffect(() => {
    if (AdsContext && AdsContext.eventBus) {
      AdsContext.eventBus.on('ads:initialized', () => {
        loadScript('https://www.dwin2.com/pub.304459.min.js');
      });
    }
  }, [AdsContext, buzz]);

  return null;
}
