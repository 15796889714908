import { useTranslation } from '../../i18n';
import { isNews } from '../../utils/isNews';
import { container, text, buzzfeed_news } from './commerce-disclaimer.module.scss';


function CommerceDisclaimer({ buzz }) {
  const { t } = useTranslation('common');
  let message = 'commerce_disclaimer';

  if(!buzz.isShopping && !buzz.tags.some(tag => tag.match('--commerce-disclaimer'))) {
    return null;
  }
  //For non-english editions, use translated commerce-disclaimer, not news specific one
  if (isNews(buzz) && buzz.language === 'en') {
    message = 'commerce_disclaimer_news'
  }

  return (
    <div className={container}>
      <p className={`${text} ${isNews(buzz) ? buzzfeed_news : ''}`}>{t(message)}</p>
    </div>
  );
}

export default CommerceDisclaimer;
