import { useCallback } from 'react';
import { isInternalUrl } from '@buzzfeed/bf-utils/lib/internal-url';
import {
  trackClientInternalLink,
  trackClientExternalLink,
} from './client-event-tracking';

/**
 * Returns function for tracking events
 *
 * Import at the top of the component/file as:
 * const trackingFn = useBreakingBarTracking(buzz, base_url)
 *
 * Pass the tracking fn to the comments component via react props
 * eventData will be provided by the component when it fires the event
 *
 * @returns {Function}
 */
export function useBreakingBarTracking(buzz, base_url) {
  const baseUrl = new URL(base_url).hostname;

  const webContentData = {
    subunit_name: 'breaking_bar',
    subunit_type: 'component',
    item_type: 'button',
    unit_type: 'nav',
    unit_name: 'main',
  };

  return useCallback(
    data => {
      if (isInternalUrl(data.breaking_url, baseUrl)) {
        trackClientInternalLink(buzz, {
          ...webContentData,
          item_name: data.label.toLocaleLowerCase(),
          target_content_type: 'url',
          target_content_id: data.breaking_url,
        });
      } else {
        trackClientExternalLink(buzz, {
          ...webContentData,
          item_name: data.label.toLocaleLowerCase(),
          target_content_url: data.breaking_url,
        });
      }
    },
    [buzz]
  );
}
