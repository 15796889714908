import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DestinationContext from '../../contexts/destination';
import styles from './sectiontitle.module.scss';

export function SectionTitle({ children, id }) {
  const { destination } = useContext(DestinationContext);
  if (!children) {
    return null;
  }
  return (
    <h2 id={id} className={`${styles.sectionTitle} ${styles[destination]}`}>
      {children}
    </h2>
  );
}

SectionTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  id: PropTypes.string,
};

export default SectionTitle;
