import React from 'react';
import styles from './pageOverlay.module.scss';

export function PageOverlay({ onOverlayClick }) {
  const onClick = () => {
    onOverlayClick(true);
  };

  return (
    <div
      role="presentation"
      className={styles.pageOverlay}
      onClick={onClick}
      onKeyDown={onClick}
    />
  );
}

export default PageOverlay;
