import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from '../../i18n';
import {
  attachClientImpressionHandler,
  attachClientInternalLinkHandler,
  attachClientExternalLinkHandler,
  trackClientContentAction,
} from '../../hooks/analytics/client-event-tracking';
import styles from './newsfooter.module.scss';
import { CdnImage } from '@buzzfeed/react-components';
import downsizeImage from '../../utils/downsizeImage';
import { isNews } from '../../utils/isNews';

const UserBio = ({ buzz, index }) => {
  const { t } = useTranslation('common');
  const tipLinkRef = useRef(null);
  const bylineRef = useRef(null);
  const topBylineRef = useRef(null);
  const { bylines } = buzz;
  const { id, bio, display_name, email, username, avatar, title } = bylines[index];
  const byline = {
    username,
    title,
    display_name: display_name || username,
    id,
    email,
    bio,
    avatar,
  };
  const authorLink = isNews(buzz) ? `/author/${byline.username}` : `/${byline.username}`;
  byline.contact = t('contact_at_email', { authorLink, email, display_name });

  const unitTrackingData = useMemo(
    () => ({
      unit_type: 'buzz_body',
      unit_name: buzz.id,
      subunit_name: 'author',
      subunit_type: 'component',
      position_in_subunit: index,
      item_type: 'profile',
      item_name: 'byline',
    }),
    [buzz.id, index]
  );

  const trackEmailClick = useCallback(() => {
    trackClientContentAction(buzz, {
      ...unitTrackingData,
      action_type: 'reply',
      action_value: byline.id,
    });
  }, [unitTrackingData, byline.id]);

  useEffect(() => {
    let detachExternalLinkTracking;
    if (tipLinkRef && tipLinkRef.current) {
      detachExternalLinkTracking = attachClientExternalLinkHandler(
        tipLinkRef.current,
        buzz,
        {
          ...unitTrackingData,
          item_name: 'send_tips',
          item_type: 'text',
          position_in_subunit: null,
          target_content_url: 'https://tips.buzzfeed.com',
        }
      );
    }
    return () => {
      if (typeof detachExternalLinkTracking === 'function') {
        detachExternalLinkTracking();
      }
    };
  }, [tipLinkRef]);

  useEffect(() => {
    let detachInternalLinkTracking;
    let detachImpressionLinkTracking;
    let bylineEmail;
    if (bylineRef && bylineRef.current) {
      const bylineLinks = bylineRef.current.querySelectorAll('a');
      const bylineLink = bylineLinks[0];
      bylineEmail = bylineLinks[1];
      if (bylineLink) {
        const trackingData = {
          ...unitTrackingData,
          target_content_type: 'user',
          target_content_id: byline.id,
        };
        detachImpressionLinkTracking = attachClientImpressionHandler(
          bylineLink,
          buzz,
          trackingData
        );
        detachInternalLinkTracking = attachClientInternalLinkHandler(
          bylineLink,
          buzz,
          trackingData
        );
      }

      if (bylineEmail) {
        bylineEmail.addEventListener('click', trackEmailClick);
      }
    }
    return () => {
      if (typeof detachInternalLinkTracking === 'function') {
        detachInternalLinkTracking();
      }
      if (typeof detachImpressionLinkTracking === 'function') {
        detachImpressionLinkTracking();
      }
      if (bylineEmail) {
        bylineEmail.removeEventListener('click', trackEmailClick);
      }
    };
  }, [bylineRef]);

  useEffect(() => {
    let detachInternalLinkTracking;
    if (topBylineRef && topBylineRef.current) {
      detachInternalLinkTracking = attachClientInternalLinkHandler(
        topBylineRef.current,
        buzz,
        {
          ...unitTrackingData,
          target_content_type: 'user',
          target_content_id: byline.id,
        }
      );
    }
    return () => {
      if (typeof detachInternalLinkTracking === 'function') {
        detachInternalLinkTracking();
      }
    };
  }, [topBylineRef]);

  return (
    <>
      <div className={styles.newsByline}>
        <a
          ref={topBylineRef}
          href={authorLink}
          className={styles.headlineByline}
        >
          <div className={styles.bylineContainer}>
            <CdnImage
              src={downsizeImage(byline.avatar)}
              className={styles.avatar}
              alt={byline.display_name}
            />
            <div className={styles.bylineText}>
              <span
                className={styles.bylineName}
                dangerouslySetInnerHTML={{ __html: byline.display_name }}
              />
              <p className={styles.position}>{byline.title}</p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

const BuzzNewsFooter = ({ buzz = {} }) => {
  const { bylines } = buzz;
  if (
    buzz.metavertical !== 'news' ||
    buzz.tags.includes('--no-bio') ||
    !bylines.length
  ) {
    return null;
  }

  return (
    <div className={`${styles.newsFooter} embed-reporter-cards`}>
      <ul className={styles.reporterCardList}>
        {bylines.map((byline, index) => (
          <li className={styles.reporterCardItem} key={index}>
            <UserBio index={index} buzz={buzz} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BuzzNewsFooter;
