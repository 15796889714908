import { useCallback, useContext, useEffect, useState } from 'react';
import BuzzContext from '../../contexts/buzz';
import DestinationContext from '../../contexts/destination';
import { useFetch } from '@buzzfeed/react-components';
import { useUserId } from '../useUserId';
import { trackClientContentAction } from '../analytics/client-event-tracking';

/**
 * @typedef Reaction
 * @property {String} name - name of the reaction
 * @property {Number} [count=0] - number of count this reaction has
 * @property {Function} [increment] - callback for when the number of count should be incremented
 * @property {function(Number):Number} [height] - computes bar height for this reaction
 */

/**
 * Returns reaction bar height
 * @param {Number} proportion - the proportion of count for this bar
 */
function computeItemHeight(maxCounts, count) {
  const stepHeight = 3;
  const maxHeight = stepHeight * 30;

  // Under a certain number of reactions, we make them all stepHeight
  if (stepHeight * maxCounts < maxHeight) {
    return count * stepHeight;
  }

  // If that's the biggest one, then we just add stepHeight
  if (count > maxCounts) {
    return maxHeight + stepHeight;
  }

  // Otherwise we calculate with prorata
  return Math.round((count * maxHeight) / maxCounts);
}

/**
 * Sorts items by count (descending)
 */
const sortVotesDesc = (r1, r2) => r2.count - r1.count;

/**
 * Returns a function to add count for a reaction with the specified name
 * @param {String} reactionName - name of the reaction
 * @param {Number} count - number to add to reaction's count
 */
const addVotesByName = (reactionName, count = 1) => reaction =>
  reaction.name === reactionName
    ? {
        ...reaction,
        count: reaction.count + count,
      }
    : reaction;

/**
 * State and render hook: enhances allowed reactions with API data and methods to submit reactions
 *
 * returns reactions data and reaction submission errors
 * @param {Array<Reaction>} allowedReactions - list of allowed reactions for a given post
 */
export function useReactions(isActive, isNew) {
  const buzz = useContext(BuzzContext);
  const { base_url, destination } = useContext(DestinationContext);
  const [reactions, setReactions] = useState([]);
  const [lastReacted, setLastReacted] = useState(null);
  const [serverError, setServerError] = useState(null);

  const clientUuid = useUserId();

  const [{ error, data }, setFetchUrl] = useFetch(null);

  const incrementReaction = useCallback(reactionName => {
    setLastReacted(reactionName);
    setReactions(prev => prev.map(addVotesByName(reactionName)));
  }, []);

  useEffect(() => {
    if (!buzz || !isActive) {
      return;
    }
    const getQuery = `edition=${buzz.country_code}`;

    setFetchUrl(isNew ?
      `${base_url}/content-reactions-api/v2/emoji/buzz/${buzz.id}?${getQuery}` :
      `${base_url}/content-reactions-api/v1/buzz/${buzz.id}?${getQuery}`
    );
  }, [base_url, buzz, isActive, setFetchUrl]);

  // Effect for fetching data for reactions from API
  useEffect(() => {
    if (error) {
      setServerError(error);
      return;
    }
    if (data && data.reactions) {
      setReactions(() => {
        const sortedReactions = data.reactions.sort(sortVotesDesc);

        // first item in the sorted list has the biggest number of count
        const [{ count: maxCounts } = {}] = sortedReactions;

        return sortedReactions.map(reaction => ({
          ...reaction,
          increment: () => incrementReaction(reaction.name),
          height: count => computeItemHeight(maxCounts, count),
        }));
      });
    }
  }, [error, data, incrementReaction]);

  // Effect for submitting a new reaction
  useEffect(() => {
    async function submitReaction(reaction) {
      try {
        const postQuery = [
          `reaction=${reaction}`,
          `client_uuid=${clientUuid}`,
          `edition=${buzz.country_code}`,
        ].join('&');
        const url = isNew ?
          `${base_url}/content-reactions-api/v2/emoji/buzz/${buzz.id}?${postQuery}&page_type=buzz&page_id=${buzz.id}` :
          `${base_url}/content-reactions-api/v1/buzz/${buzz.id}?${postQuery}`;
        const response = await fetch(
          url,
          { method: 'POST' }
        );
        const respData = await response.json();
        if (!respData || !respData.success) {
          setServerError('request failed');
          return;
        }
        trackClientContentAction(buzz, {
          action_type: 'react',
          action_value: reaction,
          item_name: reaction,
          item_type: 'button',
          subunit_name: 'reactions',
          subunit_type: 'component',
          unit_name: buzz.id,
          unit_type: 'buzz_bottom',
        });
      } catch (err) {
        setServerError(err);
      }
    }

    if (!lastReacted) {
      return;
    }

    submitReaction(lastReacted);
  }, [base_url, buzz, destination, lastReacted, clientUuid]);

  return {
    reactions,
    lastReacted,
    serverError,
  };
}
