import LazyLoad from '../LazyLoad';
import Package from './index';
import NewPackage from './package-new';

export default function PackageLazy({isNewDesign, ...props}) {
  return (
    <LazyLoad>
      { isNewDesign ? <NewPackage /> : <Package {...props} /> }
    </LazyLoad>
  );
}
