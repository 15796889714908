import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import styles from './package.module.scss';
import { usePackageTracking } from '../../hooks/analytics/usePackageTracking';
import { usePackage } from '../../hooks/usePackage';
import BuzzContext from '../../contexts/buzz';
import { CaretRightIcon, CdnImage } from '@buzzfeed/react-components';
import { truncateHTML } from '@buzzfeed/bf-utils/lib/truncate';
import { urlToId } from '../../utils/url';
import { isInternalUrl } from '@buzzfeed/bf-utils/lib/internal-url';
import { ShoppingWrapper } from './shopping/ShoppingWrapper';
import { CaretRight } from './shopping/caret-right';

function Package() {
  const buzz = useContext(BuzzContext);

  const packageData = usePackage({ buzz });
  const packageCategory = packageData ? packageData.category : '';

  if (!packageData) {
    return null;
  }
  const [splashItem, ...items] = packageData.results.slice(0, 5);

  const themeClassName =
    packageData.template !== 'default' &&
    packageData.theme &&
    styles[packageData.theme]
      ? styles[packageData.theme]
      : styles['default'];

  return (
    <>
      <ShoppingWrapper role="region" aria-label={packageData.title}>
        <div
          className={themeClassName}
          role="region"
          aria-label={packageData.title}
        >
          <div className={styles.header}>
            <PackageHeader
              template={packageData.template}
              title={packageData.title}
            />
            <PackageTopCTA
              item={packageData.cta}
              category={packageCategory}
              template={packageData.template}
            />
            <Countdown
              endTime={packageData.countdown}
              template={packageData.template}
            />
          </div>
          <div>
            <PackageSplashItem
              key={splashItem.id}
              item={splashItem}
              category={packageCategory}
            />
            <ul className={styles.items}>
              {items.map((item, index) => (
                <PackageListItem
                  key={item.id}
                  item={item}
                  index={index + 1}
                  category={packageCategory}
                />
              ))}
            </ul>
          </div>
          <PackageButton
            item={packageData.cta}
            category={packageCategory}
            position={items.length + 1}
          />
        </div>
      </ShoppingWrapper>
    </>
  );
}

function PackageSplashItem({ item = {}, category }) {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      truncateHTML(element, 180);
    }
  });

  return (
    <div className={styles.splash}>
      <PackageItem item={item} index={0} category={category}>
        <div className={styles.textWrap}>
          <h3 className={styles.text}>{item.name}</h3>
          <p className={styles.dek} ref={ref}>
            {item.description}
          </p>
        </div>
      </PackageItem>
    </div>
  );
}

function PackageListItem({ item = {}, index, category }) {
  return (
    <li className={styles.item}>
      <PackageItem item={item} index={index} category={category}>
        <div className={styles.text}>{item.name}</div>
      </PackageItem>
    </li>
  );
}

function PackageItem({ item, children, index, category }) {
  const targetTracking = {};
  if (isInternalUrl(item.url)) {
    targetTracking.target_content_type = 'buzz';
    targetTracking.target_content_id = item.id;
  } else {
    // for external links
    targetTracking.target_content_url = item.url;
    // for impressions
    targetTracking.target_content_type = 'url';
    targetTracking.target_content_id = item.url;
  }
  const unitTrackingData = useMemo(
    () => ({
      ...item,
      item_name: item.id,
      item_type: index === 0 ? 'splash' : 'card',
      position_in_subunit: index,
      index: index,
      category: category,
      ...targetTracking,
      data_source_name: 'site_component_api',
    }),
    [item, index, category, targetTracking]
  );
  const unitEl = usePackageTracking(unitTrackingData);

  return (
    <article>
      <a href={item.url} className={styles.link} ref={unitEl}>
        <CdnImage
          className={styles.img}
          src={item.image}
          alt={item.image_alt_text}
        />
        {children}
      </a>
    </article>
  );
}

function PackageButton({ item, category, position }) {
  const targetTracking = {
    item_name: urlToId(item.url),
    position_in_subunit: position,
    target_content_type: 'feed',
    target_content_id: urlToId(item.url),
  };

  const unitTrackingData = useMemo(
    () => ({
      ...item,
      item_type: 'text',
      index: 'cta',
      category: category,
      data_source_name: 'site_component_api',
      ...targetTracking,
    }),
    [item, category, targetTracking]
  );
  const unitEl = usePackageTracking(unitTrackingData);

  return (
    <a className={styles.ctaBottom} href={item.url} ref={unitEl}>
      {item.text}
      <CaretRight className={styles.ctaIcon} />
    </a>
  );
}

function PackageHeader({ title, template }) {
  return (
    <div className={styles.headerWrap}>
      <PackageTitle title={title}>
        <PackageFireIcon template={template} />
      </PackageTitle>
      <PackageSubTitle template={template} />
    </div>
  );
}

function PackageTitle({ title, children }) {
  return (
    <h2 className={styles.headerText}>
      {children}
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </h2>
  );
}

function PackageFireIcon({ template }) {
  if (template !== 'countdown' && template !== 'megasplash') {
    return null;
  }
  return (
    <img
      className={styles.fireIcon}
      src={require(`./package-fire.svg`)}
      alt="Fire"
    />
  );
}

function PackageSubTitle({ template }) {
  if (template !== 'countdown' && template !== 'megasplash') {
    return null;
  }

  return (
    <div className={styles.packageSubTitle}>THE BEST DEALS ON THE INTERNET</div>
  );
}

function PackageTopCTA({ item, category, template }) {
  const unitTrackingData = useMemo(
    () => ({
      ...item,
      item_type: 'text',
      item_name: urlToId(item.url),
      target_content_type: 'feed',
      target_content_id: urlToId(item.url),
      index: 'cta',
      category: category,
      data_source_name: 'site_component_api',
    }),
    [item, category]
  );
  const unitEl = usePackageTracking(unitTrackingData);

  if (template !== 'themed') {
    return null;
  }

  return (
    <div className={styles.ctaTopWrap}>
      <a className={styles.ctaTop} href={item.url} ref={unitEl}>
        {item.text}
        <CaretRightIcon className={styles.rightArrow} height={14} width={14} />
      </a>
    </div>
  );
}

function Countdown({ endTime, template }) {
  const [timeToEnd, setTimeToEnd] = useState(
    parseInt(endTime, 10) * 1000 - Date.now()
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeToEnd(timeToEnd - 1000);
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeToEnd]);

  if (template !== 'countdown' && template !== 'megasplash') {
    return null;
  }

  if (timeToEnd <= 0) {
    return null;
  }

  let leftTime = {
    seconds: Math.floor((timeToEnd / 1000) % 60),
    minutes: Math.floor((timeToEnd / 1000 / 60) % 60),
    hours: Math.floor((timeToEnd / (1000 * 60 * 60)) % 24),
    days: Math.floor(timeToEnd / (1000 * 60 * 60 * 24)),
  };

  return (
    <div className={styles.countdown} aria-label="Time Left in Countdown">
      <div className={styles.countdownItem}>
        <span className={styles.countdownNum}>{leftTime.days}</span>
        <span className={styles.countdownUnit}>DAYS</span>
      </div>
      <div className={styles.countdownItem}>
        <span className={styles.countdownNum}>{leftTime.hours}</span>
        <span className={styles.countdownUnit}>HRS</span>
      </div>
      <div className={styles.countdownItem}>
        <span className={styles.countdownNum}>{leftTime.minutes}</span>
        <span className={styles.countdownUnit}>MINS</span>
      </div>
      <div className={styles.countdownItem}>
        <span className={styles.countdownNum}>{leftTime.seconds}</span>
        <span className={styles.countdownUnit}>SECS</span>
      </div>
    </div>
  );
}

export default Package;
