import React, { useMemo, useContext } from 'react';
import { CaretLeftIcon } from '@buzzfeed/react-components';
import { withTranslation } from '../../../../i18n';
import { useUnitTracking } from '../../../../hooks/analytics/useUnitTracking';
import BuzzContext from '../../../../contexts/buzz';
import styles from './headline-comments.module.scss';

function HeadlineComments({ t }) {
  const buzz = useContext(BuzzContext);

  const unitTrackingData = useMemo(
    () => ({
      unit_type: 'buzz_head',
      unit_name: buzz.id,
      item_name: 'return_to_article',
      item_type: 'text',
      target_content_type: 'buzz',
      target_content_id: buzz.id,
    }),
    [buzz]
  );
  const unitEl = useUnitTracking(unitTrackingData);

  return (
    <div className={styles.headlineComments}>
      <a ref={unitEl} href={buzz.canonical_url} className={`blue-link ${styles.returnLink}`}>
        <CaretLeftIcon className={styles.returnIcon} />
        <span>{t('return_to_article')}</span>
      </a>
      <div className={styles.commentOn}>{t('comments_on')}</div>
    </div>
  );
}

export default withTranslation('common')(HeadlineComments);
