import { useEffect, useState, useContext } from 'react';
import { CLUSTER } from '../../constants';
import ABeagleContext from '../../contexts/abeagle';
import testData from './testData';
import { buildRecsysQuery } from '../../utils/buildRecsysQuery';
import DestinationContext from '../../contexts/destination';
import { stripTags } from '../../utils/sanitize';

const ShoppingPackage = (() => {
  const VARIANT_TO_RECSYS_ENDPOINT = {
    control: 'shopping-package-internal',
  };

  /* Returns the full recsys URL for *variant* */
  function getURL(variant) {
    const endpoint = VARIANT_TO_RECSYS_ENDPOINT[variant];
    let url = buildRecsysQuery(endpoint, { mode: 'mobile' });
    return url;
  }

  /* HACK HACK HACK HACK
   * This is a short term solution for our MVP experiment.
   * If this experiment is successful, we will roll out a long term solution
   * which transforms the result in the backend (sca, weaver, recsys) following the established patterns
   * HACK HACK HACK HACK
   */
  const DEFAULTDATA = {
    aria_title: 'Shopping',
    component_name: 'packages',
    countdown: 0,
    cta: {
      text: 'See more Shopping',
      url: 'https://www.buzzfeed.com/shopping',
    },
    data_source: 'recsys_api',
    package_id: '',
    results: [],
    template: 'default',
    theme: 'blue',
    title: '',
    treatments: ['shopping', 'visual'],
  };
  // fetch and transform package data
  const fetchData = async (variant, originStr, staticUrl) => {
    const url = staticUrl || getURL(variant || 'control');
    // start with the default package data.
    let data = null;

    try {
      const response = await fetch(url, {});
      let recsysData = await response.json();

      // return early if there are no results
      const hasData = 'results' in recsysData && recsysData.results.length;
      if (!hasData) {
        return null;
      }

      data = DEFAULTDATA;

      // set the package_id to be the first buzz_id
      data.package_id = recsysData.results[0].id;

      // add origin params to the urls and use a subset of the recsys data.
      data.results = recsysData.results.map(function (post) {
        return {
          author: '',
          canonical_path: post.canonical_path,
          created_at: '',
          data_source: post.data_source_name,
          data_source_name: post.data_source_name,
          description: stripTags(post.description),
          id: post.id,
          image: post.image,
          image_alt_text: post.image_alt,
          name: post.name,
          url: `${post.url}?origin=${originStr}`,
        };
      });

      // add origin data to the cta url
      if (data.cta && data.cta.url) {
        data.cta.url += `?origin=${originStr}`;
      }
    } catch (error) {
      if (CLUSTER === 'dev') {
        console.log('error fetching data', error);
      }
    }
    return data;
  };

  return {
    fetchData: fetchData,
  };
})();

export function usePackage({ buzz }) {
  const [packageData, setPackageData] = useState(null);
  const { getFeatureFlagValue, getExperimentValue, experiments } = useContext(ABeagleContext);
  const countryCode = buzz.country_code;
  const isUS = buzz.isUS;
  let category = (buzz.classification.section || buzz.category).toLowerCase();

  // collect buzz Laser Tags from bf_content_description namespace and topic tag_type
  const laserTags =
    (buzz.laser_tags &&
      buzz.laser_tags.bf_content_description &&
      buzz.laser_tags.bf_content_description.topic) ||
    [];

  // merge CMS tags and Laser tags together
  const buzzAndLaserTags = [
    ...buzz.tags.filter(tag => !tag.includes('--')),
    ...laserTags.map(tag => tag.tag_name),
  ];

  const isTaggedWithCoronavirus =
    buzzAndLaserTags.includes('coronavirus') ||
    buzzAndLaserTags.includes('Coronavirus');
  const { base_url } = useContext(DestinationContext);

  // mapping between CMS tags / Laser Tags to package name
  const tagsToPackages = [
    {
      tags: ['cv-whattowatch', 'coronavirus_what_to_watch'],
      package: 'coronavirus-what-to-watch',
    },
    {
      tags: ['cv-foodrecipes', 'coronavirus_food_recipes'],
      package: 'coronavirus-food-recipes',
    },
    {
      tags: ['cv-busyathome', 'coronavirus_busy_at_home'],
      package: 'coronavirus-busy-at-home',
    },
    {
      tags: ['cv-parenting', 'coronavirus_parenting'],
      package: 'coronavirus-parenting',
    },
    {
      tags: ['cv-smilebreak', 'coronavirus_smile_break'],
      package: 'coronavirus-smile-break',
    },
    {
      tags: ['cv-explainer', 'coronavirus_explainer'],
      package: 'coronavirus-explainers',
    },
    {
      tags: ['cv-backtoschool2020', 'coronavirus_back_to_school_2020'],
      package: 'coronavirus-back-to-school-2020',
    },
  ];

  useEffect(() => {
    // we should load package only after ger experiments results from Abeagle
    if (!experiments.loaded) {
      return;
    }
    const isSensitive = buzz?.flags?.sensitive || false;
    const isAllBpageShoppingEnabled = buzz.isUS && !buzz.isAd && !isSensitive && !buzzAndLaserTags.includes('ai quiz') && !buzz.shouldHideRecircSection;
    const isElectionPackageEnabled = getFeatureFlagValue(
      'election2020_package_bfdc_bpages'
    );
    const isPrimeDay = getFeatureFlagValue(
      'prime-day-2024-bpage-recirc'
    );
    const shoppingPackageVariant = getExperimentValue(
      'shopping-package-v4-ml-1499',
      { rejectErrors: false }
    );
    let packageCategory = '';


    if (isAllBpageShoppingEnabled) {
      packageCategory = 'shopping';
    } else if (isTaggedWithCoronavirus) {
      packageCategory = 'coronavirus';

      for (const packageInfo of tagsToPackages) {
        for (const tag of packageInfo.tags) {
          if (buzzAndLaserTags.includes(tag)) {
            packageCategory = packageInfo.package;
            break;
          }
        }
      }
      // we should show shopping package for nifty and shopping categories in US posts
    } else if ((category === 'nifty' || category === 'shopping') && isUS) {
      packageCategory = 'shopping';
    } else if (isElectionPackageEnabled) {
      packageCategory = 'election-2020-package';
    }

    // fetch package data
    const fetchData = async () => {
      const url = `${base_url}/site-component/v1/en-us/packages/${packageCategory}`;
      try {
        const response = await fetch(url, {});
        let data = await response.json();
        if ('results' in data && data.results.length) {
          data.results = data.results.map(function(post) {
            post.url += '?origin=' + originMaps(packageCategory, category);
            post.description = stripTags(post.description);
            return post;
          });
          if (data.cta && data.cta.url) {
            data.cta.url += '?origin=' + originMaps(packageCategory, category);
          }

          setPackageData({ ...data, category: packageCategory });
        }
      } catch (error) {
        if (CLUSTER === 'dev') {
          let data = testData;
          if ('results' in data && data.results.length) {
            data.results = data.results.map(function(post) {
              post.url += '?origin=' + originMaps(packageCategory, category);
              return post;
            });
            setPackageData({ ...data, category: packageCategory });
          }
        }
      }
    };

    const shouldIncludeCoronavirusPackage =
      !['de-de', 'es', 'es-es', 'fr-fr', 'ja-jp', 'pt-br'].includes(
        countryCode
      ) &&
      [
        'coronavirus',
        'coronavirus-what-to-watch',
        'coronavirus-food-recipes',
        'coronavirus-busy-at-home',
        'coronavirus-parenting',
        'coronavirus-smile-break',
        'coronavirus-explainers',
        'coronavirus-back-to-school-2020',
      ].includes(packageCategory);

    if (isPrimeDay && isUS && packageCategory === 'shopping') {
      const originStr = 'bprime_pkg';
      const staticUrl = `${base_url}/site-component/v1/prime-day`;
      ShoppingPackage.fetchData(shoppingPackageVariant, originStr, staticUrl).then(data => {
        if (data) {
          setPackageData({ ...data, category: packageCategory });
        }
      });
    } else if (isUS && packageCategory === 'shopping') {
      const originStr = originMaps(packageCategory, category);
      ShoppingPackage.fetchData(shoppingPackageVariant, originStr).then(data => {
        if (data) {
          setPackageData({ ...data, category: packageCategory });
        }
      });
    } else if (shouldIncludeCoronavirusPackage || isElectionPackageEnabled) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buzz.tags,
    category,
    isUS,
    countryCode,
    experiments,
    isTaggedWithCoronavirus,
  ]);

  return packageData;
}

function originMaps(packageCategory, buzzCategory) {
  const packageBuzzCategoryMap = {
    shopping: {
      shopping: 'bshp',
      nifty: 'nifty_pkg_bpage',
    },
  };
  const origins = {
    giftguide: 'bgg',
    coronavirus: 'bcv',
    'coronavirus-what-to-watch': 'bcvwtw',
    'coronavirus-food-recipes': 'bcvfr',
    'coronavirus-busy-at-home': 'bcvbah',
    'coronavirus-parenting': 'bcvp',
    'coronavirus-smile-break': 'bcvsmb',
    'coronavirus-explainers': 'bcve',
    'coronavirus-back-to-school-2020': 'bcvbts',
    'election-2020-package': 'bp2020election',
  };

  return packageBuzzCategoryMap[packageCategory] &&
    packageBuzzCategoryMap[packageCategory][buzzCategory]
    ? packageBuzzCategoryMap[packageCategory][buzzCategory]
    : origins[packageCategory] || 'shp';
}
