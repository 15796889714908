// Next Images doesn't work well with dynamic imports, and imports generally
// are meant to be statically analyzable, so this file imports them all explicitly

import de_cute from '../../../../public/static/images/badges/de/cute.svg';
import de_fail from '../../../../public/static/images/badges/de/fail.svg';
import de_lol from '../../../../public/static/images/badges/de/lol.svg';
import de_omg from '../../../../public/static/images/badges/de/omg.svg';
import de_win from '../../../../public/static/images/badges/de/win.svg';
import de_wtf from '../../../../public/static/images/badges/de/wtf.svg';

import en_cute from '../../../../public/static/images/badges/en/cute.svg';
import en_fail from '../../../../public/static/images/badges/en/fail.svg';
import en_lol from '../../../../public/static/images/badges/en/lol.svg';
import en_omg from '../../../../public/static/images/badges/en/omg.svg';
import en_win from '../../../../public/static/images/badges/en/win.svg';
import en_wtf from '../../../../public/static/images/badges/en/wtf.svg';

import es_cute from '../../../../public/static/images/badges/es/cute.svg';
import es_fail from '../../../../public/static/images/badges/es/fail.svg';
import es_lol from '../../../../public/static/images/badges/es/lol.svg';
import es_omg from '../../../../public/static/images/badges/es/omg.svg';
import es_win from '../../../../public/static/images/badges/es/win.svg';
import es_wtf from '../../../../public/static/images/badges/es/wtf.svg';

import es_es_cute from '../../../../public/static/images/badges/es-es/cute.svg';
import es_es_fail from '../../../../public/static/images/badges/es-es/fail.svg';
import es_es_lol from '../../../../public/static/images/badges/es-es/lol.svg';
import es_es_omg from '../../../../public/static/images/badges/es-es/omg.svg';
import es_es_win from '../../../../public/static/images/badges/es-es/win.svg';
import es_es_wtf from '../../../../public/static/images/badges/es-es/wtf.svg';

import fr_cute from '../../../../public/static/images/badges/fr/cute.svg';
import fr_fail from '../../../../public/static/images/badges/fr/fail.svg';
import fr_lol from '../../../../public/static/images/badges/fr/lol.svg';
import fr_omg from '../../../../public/static/images/badges/fr/omg.svg';
import fr_win from '../../../../public/static/images/badges/fr/win.svg';
import fr_wtf from '../../../../public/static/images/badges/fr/wtf.svg';

import general_quiz from '../../../../public/static/images/badges/general/quiz.svg';
import general_fail from '../../../../public/static/images/badges/general/fail.svg';
import general_trending from '../../../../public/static/images/badges/general/trending.svg';
import general_viral from '../../../../public/static/images/badges/general/viral.svg';

import ja_cute from '../../../../public/static/images/badges/ja/cute.svg';
import ja_fail from '../../../../public/static/images/badges/ja/fail.svg';
import ja_lol from '../../../../public/static/images/badges/ja/lol.svg';
import ja_omg from '../../../../public/static/images/badges/ja/omg.svg';
import ja_win from '../../../../public/static/images/badges/ja/win.svg';
import ja_wtf from '../../../../public/static/images/badges/ja/wtf.svg';

import pt_cute from '../../../../public/static/images/badges/pt/cute.svg';
import pt_fail from '../../../../public/static/images/badges/pt/fail.svg';
import pt_lol from '../../../../public/static/images/badges/pt/lol.svg';
import pt_omg from '../../../../public/static/images/badges/pt/omg.svg';
import pt_win from '../../../../public/static/images/badges/pt/win.svg';
import pt_wtf from '../../../../public/static/images/badges/pt/wtf.svg';

const ImageMap = {
  de: {
    cute: de_cute,
    fail: de_fail,
    lol: de_lol,
    omg: de_omg,
    win: de_win,
    wtf: de_wtf,
  },
  en: {
    cute: en_cute,
    fail: en_fail,
    lol: en_lol,
    omg: en_omg,
    win: en_win,
    wtf: en_wtf,
  },
  es: {
    cute: es_cute,
    fail: es_fail,
    lol: es_lol,
    omg: es_omg,
    win: es_win,
    wtf: es_wtf,
  },
  'es-es': {
    cute: es_es_cute,
    fail: es_es_fail,
    lol: es_es_lol,
    omg: es_es_omg,
    win: es_es_win,
    wtf: es_es_wtf,
  },
  fr: {
    cute: fr_cute,
    fail: fr_fail,
    lol: fr_lol,
    omg: fr_omg,
    win: fr_win,
    wtf: fr_wtf,
  },
  general: {
    fail: general_fail,
    quiz: general_quiz,
    trending: general_trending,
    viral: general_viral,
  },
  ja: {
    cute: ja_cute,
    fail: ja_fail,
    lol: ja_lol,
    omg: ja_omg,
    win: ja_win,
    wtf: ja_wtf,
  },
  pt: {
    cute: pt_cute,
    fail: pt_fail,
    lol: pt_lol,
    omg: pt_omg,
    win: pt_win,
    wtf: pt_wtf,
  },
};

export default ImageMap;
