/*
 * This util should only be used on the client-side in a useEffect and is
 * purely for presentation reasons.
 *
 * It *does not* provide any guarantees of escaping stored XSS attacks and
 * if you require this you should escape/filter on the server-side.
 *
 * There are functions to help you achieve this in /api/sanitize but these
 * should not be used on the client.
 */

export const stripTags = (html = '') => {
  const node = document.createElement('div');
  node.innerHTML = html;
  return node.textContent;
}
