import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { image_service_url } from '../../../../constants';
import {
  getBadgeLanguage,
  sortBadges,
  invalidType,
  badgeExcludeList,
} from './badges';
import TrendingBadge from './TrendingBadge.jsx';
import styles from './headline-badges.module.scss';
import { useUnitTracking } from '../../../../hooks/analytics/useUnitTracking';
import { CdnImage } from '@buzzfeed/react-components';
import badgeSvgMap from './badge-svg-map';

const maxBadgeCount = 3;

const tiers = {
  Triggered: { priority: 1 },
  Editorial: { priority: 2 },
  Reaction: { priority: 3 },
  Others: { priority: 4 },
};

const svgs = [
  'cute',
  'omg',
  'win',
  'fail',
  'wtf',
  'lol',
  'trending',
  'viral',
  'quiz',
];
const translatedSvgs = ['cute', 'omg', 'win', 'fail', 'wtf', 'lol'];

export function Badge({ index, badge, buzzId }) {
  const unitTrackingData = useMemo(
    () => ({
      eventCategory: 'Buzz:content',
      eventAction: badge.badge_type,
      eventLabel: null,
      unit_type: 'buzz_head',
      unit_name: buzzId,
      item_name: badge.link_to,
      item_type: 'badge',
      position_in_unit: index,
      target_content_type: 'feed',
      target_content_id: badge.link_to,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [badge.badge_type, buzzId, index]
  );
  const unitEl = useUnitTracking(unitTrackingData);

  return (
    <a
      ref={unitEl}
      className={styles.badge}
      href={`/badge/${badge.link_to}`}
      key={index}
    >
      {badge.has_svg && badge.badge_language ? (
        <img
          src={badgeSvgMap[badge.badge_language][badge.badge_type]}
          alt={`${badge.name} badge`}
        />
      ) : (
        <CdnImage
          src={`${image_service_url}${badge.path}`}
          alt={`${badge.name} badge`}
        />
      )}
    </a>
  );
}

function HeadlineBadges({ badges, countryCode, flags, buzzId }) {
  if (!badges) {
    return null;
  }

  const hasTrending = badges.find(badge => badge.badge_type === 'trending');

  // Do not show badge when:
  // 1) Badge is on the badge exclude list
  // 2) Badge is editorial/sponsored but the post is not
  // 3) Badge is viral and post is trending
  const filteredBadges = badges
    .filter(
      badge =>
        !badgeExcludeList.includes(badge.badge_type) &&
        !invalidType(badge, flags) &&
        (!hasTrending || (hasTrending && badge.badge_type !== 'viral'))
    )
    .map(badge => {
      const badgeType = badge.badge_type;
      badge.badge_language = translatedSvgs.includes(badgeType)
        ? getBadgeLanguage(countryCode)
        : 'general';

      badge.has_svg = svgs.includes(badgeType);

      badge.link_to = badge.link_to || badgeType;

      if (!(badge.category in tiers)) {
        badge.category = 'Others';
      }

      return { ...badge, ...tiers[badge.category] };
    });

  const displayBadges = sortBadges(filteredBadges).slice(0, maxBadgeCount);

  return (
    <ul className={styles.badgeList}>
      {displayBadges.map((badge, index) => {
        return (
          <li key={index}>
            <Badge index={index} badge={badge} buzzId={buzzId} />
          </li>
        );
      })}
      {hasTrending && (
        <li>
          <TrendingBadge buzzId={buzzId} />
        </li>
      )}
    </ul>
  );
}

HeadlineBadges.defaultProps = {
  badges: [],
  countryCode: 'en-us',
  flags: {},
  buzzId: '0',
};

HeadlineBadges.propTypes = {
  badges: PropTypes.array.isRequired,
  countryCode: PropTypes.string.isRequired,
  flags: PropTypes.object.isRequired,
  buzzId: PropTypes.string.isRequired,
};

export default HeadlineBadges;
