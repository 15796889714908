/**
 * @typedef {Object} LinkProperties
 * @property {String} href - the `href` property of the <Link>
 * @property {String} hrefAs - the `as` property of the <Link>
 * @property {String} url - the `href` property of the <a> element within a <Link>
 */

/**
 * Take an item from a recsys API response and return the properties for a React <Link> element
 * Identify recirc items that aren't handled by bf_bpage_ui.
 * These can't be loaded client side so we use the full url as the href.
 *
 * Recsys API reference: https://github.com/buzzfeed/mono/tree/master/recsys_api
 * React <Link> reference: https://knowbody.github.io/react-router-docs/api/Link.html
 *
 * @returns {LinkProperties} properties
 */
export const getRecsysUrlLinkProperties = ({
  url = '',
  canonical_path = '',
  destination_name = '',
  is_external = false,
} = {}) => {
  const isExternalLink =
    canonical_path.includes('/watch/video/') || is_external;

  const canonicalPath = canonical_path;

  return {
    href:
      isExternalLink || !url.length || !destination_name.length
        ? url
        : '/[...slug]',
    hrefAs: canonical_path.length ? canonicalPath : url,
    url,
  };
};

/**
 * Convert a URL path into a string identifier value
 * @param {String} url
 */
export const urlToId = url => {
  if (!url) {
    return '';
  }

  let id = url;
  // first see if this is a full URL or a path - we want a path
  try {
    id = new URL(url).pathname;
  } catch {
    // continue with url if not
  }

  if (id.charAt(0) === '/') {
    id = id.slice(1, id.length);
  }

  return id.replace(/\//g, '_');
};
