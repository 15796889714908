import React, { useContext } from 'react';
import dynamicImport from '../../utils/dynamic-import';
import { useBreakpoint } from '@buzzfeed/react-components';
import BuzzContext from '../../contexts/buzz';
import LazyLoad from '../LazyLoad';
import { isEligible } from './utils';

const TrendingShoppingCarousel = dynamicImport(
  () =>
    import(/* webpackChunkName: "trending-shopping-carousel" */ './index.jsx'),
  { ssr: false }
);

export default function TrendingShoppingCarouselLazy(props) {
  const buzz = useContext(BuzzContext);
  const isDesktop = useBreakpoint('(min-width: 64rem)');

  if (!isEligible(buzz, isDesktop)) {
    return null;
  }

  return (
    <LazyLoad>
      <TrendingShoppingCarousel {...props} />
    </LazyLoad>
  );
}
