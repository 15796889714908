import styles from './appPromoCard.module.scss';


export default function AppPromoCard({ content, onClick, onClose }) {
  const { titleText, descText, btnText, closeText } = content;
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
          <title>BuzzFeed Trending Arrow</title>
          <path d="M19 38C8.5 38 0 29.5 0 19S8.5 0 19 0s19 8.5 19 19-8.5 19-19 19zm9.9-19.4l-1.3-9-8.4 3.4 3.3 1.9-3.1 5.4-5.4-3-5.4 9.3 3 1.7 3.6-6.3 5.4 3.1 4.9-8.5 3.4 2z"></path>
        </svg>
      </div>
      <div className={styles.title}>{titleText}</div>
      <div className={styles.desc}>{descText}</div>
      <div className={styles.button} onClick={() => onClick()}>{btnText}</div>
      <div className={styles.close} onClick={() => onClose()}>{closeText}</div>
    </div>
  )
}

