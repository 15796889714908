import { captureException } from '@sentry/nextjs';
import { useT3 } from '../../hooks/useT3';
import { useEffect, useState, useRef } from 'react';
import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';

/**
 * useSubbuzzes loads the static dependencies before returning a T3 instance
 * which can then be safely initialised
 *
 * @param {Object} assets - the static asset dependencies for subbuzzes
 * @returns {Object} - t3 instance
 */

export function useSubbuzzes(assets = {}) {
  const t3App = useT3();
  const [loaded, setLoaded] = useState(false);
  const { current: subbuzzAssets } = useRef(assets);

  useEffect(() => {
    if (loaded) {
      return;
    }

    async function fetchAssets() {
      try {
        await Promise.all(assets.js.map(async src => await loadScript(src)));
        setLoaded(true);
      } catch (e) {
        captureException(e);
      }
    }

    if (assets.inline_js) {
      const el = document.body;
      const script = document.createElement('script');
      script.innerHTML = assets.inline_js;
      el.appendChild(script);
    }

    if (assets.js) {
      fetchAssets();
    }
  }, [subbuzzAssets, loaded]);

  return t3App && loaded ? t3App : null;
}
