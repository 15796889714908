import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './action-bar.module.scss';
import layoutStyles from '../Post/post.module.scss';
import ShareBar from '../ShareBar';
import CommentsContext from '../../contexts/comments';
import { useCommentsCtaButtonTracking } from '../../hooks/analytics/useCommentsCtaButtonTracking';
import { attachClientImpressionHandler } from '../../hooks/analytics/client-event-tracking';
import dynamicImport from '../../utils/dynamic-import';
import { isNews } from '../../utils/isNews';

const CommentsCtaButton = dynamicImport(
  () =>
    import(
      /* webpackChunkName: "comments-cta-button" */ '@buzzfeed/react-components/dist/module/lib/components/CommentsCtaButton'
    ),
  { ssr: false }
);

export function ActionBar({
  buzz,
  commentCount,
  showShareBar,
  className = '',
  destination = 'buzzfeed',
}) {
  const commentsCtaButtonTrackingFn = useCommentsCtaButtonTracking(buzz);
  const {
    commentsEnabled,
    isCommentsPanelOpen,
    toggleCommentsPanel
  } = useContext(CommentsContext);
  const onLoaded = isNews(buzz) ?
    ({ element, index, platform, shareUrl }) => {
      attachClientImpressionHandler(
        element,
        buzz,
        {
          subunit_name: 'share',
          subunit_type: 'component',
          unit_type: 'buzz_head',
          unit_name: buzz.id,
          item_name: platform,
          item_type: 'button',
          position_in_subunit: index,
          target_content_type: 'url',
          target_content_id: shareUrl
        }
      );
    } : undefined;

  useEffect(() => {
    const storedComment = window.localStorage.getItem('COMMENT_' + window.location.host + window.location.pathname);
    const openCommentsParam = (new URLSearchParams(window.location.search)).has('open_comments');
    const openCommentsPanel = storedComment || openCommentsParam;
    if (commentCount !== null && openCommentsPanel) {
      toggleCommentsPanel(true);
    }
  }, [commentCount]);

  const commentsCtaButton = (commentsEnabled &&
    <CommentsCtaButton
      commentCount={commentCount}
      track={commentsCtaButtonTrackingFn}
      locale={buzz.language || 'en'}
      destination={destination}
      onCtaClick={() => toggleCommentsPanel(true)}
      disabled={isCommentsPanelOpen}
    />
  );

  const shareBar = (showShareBar &&
    <ShareBar
      buzz={buzz}
      type="pagelevel"
      position="top_share_bar"
      className={layoutStyles.shareTop}
      onLoaded={onLoaded}
    />
  );

  if (buzz.hasQuizSharedResult) {
    return null;
  }

  return (
    <div className={`${styles.actionBar} ${styles[destination]} ${className} embed-action-bar`}>
      {!isNews(buzz) ? <>
        {/*
          Needs to use additional wrapper div to save right alignment for shareBar if there is no commentsCtaButton
          on the page
        */}
        <div className={styles.commentsCtaWrapper}>
          {commentsCtaButton}
        </div>
        {shareBar}
      </> : <>
        {shareBar}
        {commentsCtaButton}
      </>}
    </div>
  );
}

ActionBar.propTypes = {
  buzz: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

export default ActionBar;
