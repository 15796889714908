import { useEffect } from 'react';
import ABeagleContext from '../../contexts/abeagle';
import { isAmazon } from '@buzzfeed/bf-utils/lib/affiliate-link';
import { useAmazonSubtagModifier as useAmazonSubtagModifierFromPackages } from '@buzzfeed/react-components/lib/hooks/useAmazonSubtagModifier';

export function useAmazonSubtagModifier({ buzz = {} }) {
  const ref = useAmazonSubtagModifierFromPackages({ buzz, ABeagleContext });
  const element = ref?.current;

  // force link conversion in case of commerce api failure
  useEffect(() => {
    if (!buzz.is_convert_enabled || !element || element.querySelector('a[data-affiliate]')) {
      return;
    }
    element.querySelectorAll('a[href*="amazon"]:not([data-affiliate])').forEach(link => {
      if (isAmazon(link.href)) {
        // normally set on the back-end, this attribute indicates that the link is an affiliate link
        // and should be tracked and converted
        link.setAttribute('data-affiliate', 'true');
      }
    });
  }, [element, buzz]);

  return ref;
}
