import { GoogleAdsConversionTracker } from '@buzzfeed/third-party-analytics';

import {
  CLUSTER,
} from '../../../constants';

let trackingInstance = false;
const gac = () => {
  if (!trackingInstance) {
    trackingInstance = new GoogleAdsConversionTracker({ cluster: CLUSTER });
  }
  return trackingInstance;
}

export async function trackAdsConversionEvent() {
  const tracker = await gac();
  tracker.trackEvent();
}