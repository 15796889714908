import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { CaretRightIcon } from '@buzzfeed/react-components';
import styles from './headline-breadcrumb.module.scss';
import { useUnitTracking } from '../../../../hooks/analytics/useUnitTracking';
import BuzzContext from '../../../../contexts/buzz';
import { urlToId } from '../../../../utils/url';
import { isNews } from '../../../../utils/isNews';

const separators = {
  comma: (
    <span className={styles.comma} aria-hidden="true">
      ,{' '}
    </span>
  ),
  caret: (
    <CaretRightIcon
      className={`caret-separator ${styles.caret}`}
      aria-hidden="true"
    />
  ),
  midot: (
    <span className={`bold ${styles.midot}`}>&#183;</span>
  ),
};

const getSeparator = (buzz) => {
  let separator;

  switch (true) {
    case buzz.isNews:
      separator = 'default';
      break;
    case buzz.isQuiz:
      separator = 'midot';
      break;
    default:
      separator = 'caret';
  }

  return separators[separator]
}

function Breadcrumb({ buzz, text, url, index }) {

  const unitTrackingData = useMemo(
    () => ({
      eventCategory: 'Header',
      eventAction: 'link',
      eventLabel: url,
      unit_type: 'buzz_head',
      unit_name: buzz.id,
      item_name: isNews(buzz) ? text : urlToId(url),
      item_type: 'text',
      position_in_unit: isNews(buzz) ? null : index,
      position_in_subunit: isNews(buzz) ? index : null,
      subunit_name: isNews(buzz) ? 'breadcrumbs' : '',
      subunit_type: isNews(buzz) ? 'component' : '',
      target_content_type: 'feed',
      target_content_id: urlToId(url),
    }),
    [buzz.id, index, url]
  );
  const unitEl = useUnitTracking(unitTrackingData);

  return (
    <a ref={unitEl} className="metadata-link" href={url}>
      {text}
    </a>
  );
}

function BreadcrumbList({
  breadcrumbs,
  hasSecondaryBreadcrumbs = false,
}) {
  const buzz = useContext(BuzzContext);
  const separator = getSeparator(buzz);

  return (
    <>
      {breadcrumbs.map((item, index) => {
        const isLastItem = breadcrumbs.length === index + 1;
        const className = buzz.isNews ? styles.breadcrumbNewsItem : styles.breadcrumbItem;
        return (
          <li className={className} key={`${item}--${index}`}>
            <Breadcrumb buzz={buzz} text={item.text} url={item.url} index={index} />
            {(!isLastItem || hasSecondaryBreadcrumbs) && separator}
          </li>
        );
      })}
    </>
  );
}

export function HeadlineBreadcrumb({
  breadcrumbs,
  secondaryBreadcrumbs = [],
}) {
  const hasSecondaryBreadcrumbs = Boolean(secondaryBreadcrumbs.length);

  return (
    <ol>
      <BreadcrumbList breadcrumbs={breadcrumbs} hasSecondaryBreadcrumbs={hasSecondaryBreadcrumbs} />
      {hasSecondaryBreadcrumbs &&
        <BreadcrumbList breadcrumbs={secondaryBreadcrumbs} />
      }
    </ol>
  );
}

HeadlineBreadcrumb.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  secondaryBreadcrumbs: PropTypes.array,
};
