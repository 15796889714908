export const EXCLUDED_NEWS_BADGES = ['trending', 'viral'];

export const EXCLUDED_NEWS_SECTIONS = ['news'];

export const SUPPORTED_NEWS_COLLECTIONS = [
  '1999',
  '2018',
  '2018midterms',
  '2020election',
  '2020protests',
  'amtodm',
  'areaofexpertise',
  'blackfutures',
  'bodyweek2021',
  'bodyweek2022',
  'climate',
  'climatechange',
  'coronavirus',
  'emergingwriters',
  'endgame',
  'fincen-files',
  'followthis',
  'futurefood',
  'futurehistory',
  'goodnews',
  'hopesweek',
  'impeachingtrump',
  'investigatingtonyrobbins',
  'itsbrutalouthere',
  'jpg',
  'letsgetphysical',
  'loveyourlibrary',
  'mentalhealthweek',
  'moneyweek',
  'newyearsrevolution',
  'opinion',
  'opportunitycosts',
  'outsideyourbubble',
  'parentingweek2020',
  'popculturenostalgia',
  'robertdurstontrial',
  'securethebag',
  'sex',
  'sexweek',
  'sexweek2021',
  'sneakylinks',
  'stanweek',
  'thatwas2020',
  'thefacebookleaks',
  'themoneytrail',
  'themuellermemos',
  'theroyaltea',
  'thingsweloved',
  'travelweek2021',
  'unpacking',
  'weinsteinontrial',
  'whatnow',
  'whatweowe',
  'wwfsecretwar',
  'ukraine',
];
