import React, { useMemo } from 'react';
import styles from './topic-tags.module.scss';
import { useUnitTracking } from '../../hooks/analytics/useUnitTracking';

const TopicLink = ({ topic, buzz, index }) => {
  const topicName = topic.tag_name.replace(/_/g, '-');
  const unitTrackingData = useMemo(
    () => ({
      item_type: 'button',
      item_name: topic.tag_display_name,
      subunit_name: 'topic_in_article',
      subunit_type: 'component',
      unit_type: 'buzz_body',
      unit_name: buzz.id,
      target_content_type: 'feed',
      target_content_id: `topic_${topicName}`,
      position_in_subunit: index,
    }),
    [buzz.id, topic.tag_name, index]
  );

  const unitEl = useUnitTracking(unitTrackingData);

  return (
    <a ref={unitEl} href={`/topic/${topicName}`}>
      {topic.tag_display_name}
    </a>
  );
};

const TopicTags = ({ buzz }) => {
  const topics = buzz.laser_tags?.bf_content_description?.topic || [];

  if (!topics.length) {
    return null;
  }

  return (
    <div className={styles.topics}>
      <h2 className={styles.heading}>Topics in this article</h2>
      <ul>
        {topics.slice(0, 3).map((topic, index) => (
          <li key={index}>
            <TopicLink
              topic={topic}
              buzz={buzz}
              index={index}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TopicTags;
