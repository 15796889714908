import styles from './appPromoButton.module.scss';

export default function AppPromoButton({content, onClick, onClose}) {
  const { btnText } = content;
  return (
    <div className={styles.container}>
      <div className={styles.button}>
        <div
          className={styles.link}
          onClick={() => onClick()}
        >
          {btnText}
        </div>
        <div
          className={styles.close}
          onClick={() => onClose()}
        >
          &times;
        </div>
      </div>
    </div>
  )
}
