import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import FeaturedPost from './FeaturedPost/index.jsx';
import DefaultPost from './DefaultPost/index.jsx';
import { useAwin } from '../../hooks/useAwin';

function Post({ buzz, ...props }) {
  const adsContext = useContext(AdsContext);
  useAwin(buzz, adsContext);

  if (buzz.format.page_width === 'wide') {
    return <FeaturedPost buzz={buzz} {...props} />;
  }

  return <DefaultPost buzz={buzz} {...props} />;
}

Post.propTypes = {
  buzz: PropTypes.object.isRequired,
};

export default Post;
