import React, { useMemo } from 'react';

import layoutStyles from './style.module.scss';
import SectionTitle from '../SectionTitle';
import { useUnitTracking } from '../../hooks/analytics/useUnitTracking';

function TakeQuizYourself({ buzz }) {
  const unitTrackingData = useMemo(
    () => ({
      position_in_unit: 0,
      unit_type: 'buzz_body',
      unit_name: 'quiz_result',
      subunit_type: 'component',
      subunit_name: 'quiz_result_links',
      target_content_id: buzz.id,
      target_content_type: 'buzz',
      item_name: buzz.id,
      item_type: 'card',
    }),
    [buzz.id]
  );

  const unitEl = useUnitTracking(unitTrackingData);

  if (!buzz.hasQuizSharedResult) {
    return null;
  }

  return (
    <section className={layoutStyles.container}>
      <SectionTitle id="takeQuizYourself">Take this Quiz Yourself</SectionTitle>
      <a
        className={layoutStyles.quizLink}
        href={buzz['canonical_url']}
        ref={unitEl}
      >
        <img
          src={buzz.picture}
          className={layoutStyles.quizImg}
          alt={buzz.pictureAlt || ''}
        />
        <span className={layoutStyles.quizTitle}>{buzz.title}</span>
      </a>
    </section>
  );
}

export default TakeQuizYourself;
