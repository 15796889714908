import React, { useEffect, useContext, useState } from 'react';
import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';
import { isMobile } from '@buzzfeed/bf-utils/lib/device';
import ABeagleContext from '../../contexts/abeagle';
import styles from './outbrain.module.scss';

const Widget_ID_M = 'CRMB_1';
const Widget_ID_D = 'AR_1';

function Outbrain() {
  const { getFeatureFlagValue } = useContext(ABeagleContext);
  const showOutbrain = getFeatureFlagValue('RT-1041-Outbrain-on-BFN');
  const [widgetID, setWidgetID] = useState(false);

  useEffect(() => {
    if (showOutbrain && !widgetID) {
      setWidgetID(isMobile() ? Widget_ID_M : Widget_ID_D);
      loadScript('//widgets.outbrain.com/outbrain.js');
    }
  }, [showOutbrain, widgetID]);

  if (!widgetID) {
    return null;
  }

  return <div className={`${styles.OUTBRAIN} OUTBRAIN`} data-src="DROP_PERMALINK_HERE" data-widget-id={widgetID} />;
}

export default Outbrain;
