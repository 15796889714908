import { useEffect, useRef } from 'react';
import { getElementData } from '@buzzfeed/bf-utils/lib/affiliate-link';
import { isAffiliateLink } from '@buzzfeed/react-components/lib/utils/affiliate';

const onProductLinkClick = async ({ element }) => {
  if ( element.hasAttribute('data-affiliate') &&
    element.hasAttribute('data-vars-product-id')) 
  {
    const data = getElementData(element);
    const productID = data['product-id'];
    const clickedProducts = JSON.parse(
      localStorage.getItem('clickedProducts') || '[]'
    );

    const newProduct = {
      id: data['product-id'],
      timestamp: Date.now(),
    };

    const isDuplicate = clickedProducts.some(
      (product) => product.id === productID
    );

    if (productID && !isDuplicate) {
      clickedProducts.push(newProduct);
    }

    window.localStorage.setItem(
      'clickedProducts',
      JSON.stringify(clickedProducts)
    );
  }
};

export function useProductClicks({ buzz = {} }) {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }
    element.querySelectorAll('a[href]').forEach((link) => {
      if (!isAffiliateLink(link)) {
        return;
      }
      link.addEventListener('click', (ev) => {
        onProductLinkClick({
          element: ev.target.closest('a'),
        });
      });
    });
  }, [buzz]);

  return ref;
}
