import React, { useEffect } from 'react';
import styles from './trending-products.module.scss';
import { withErrorBoundary } from '@buzzfeed/react-components';
import DownArrow from '../Svgs/DownArrow';
import { useAmazonSubtagModifier } from '../../hooks/useAmazonSubtagModifier';
import { useButtonPostTap } from '../../hooks/useButtonPostTap';
import { useAffiliateLinkTracking } from '../../hooks/analytics/useAffiliateLinkTracking';
import { withTranslation, useTranslation } from '../../i18n';
import { setElementSubtags } from '@buzzfeed/bf-utils/lib/affiliate-link';
import { isMobile } from '@buzzfeed/bf-utils/lib/device';
import { truncateHTML } from '@buzzfeed/bf-utils/lib/truncate';
import composeRefs from '@seznam/compose-react-refs';
import { useLinkTracking } from '../../hooks/analytics/useLinkClickTracking';
import { trackClientInternalLink } from '../../hooks/analytics/client-event-tracking';
import { captureException } from '@sentry/nextjs';


function TrendingProducts({ t, buzz = {}, postRef }) {
  const products = buzz.trending_products;
  const label = 'trending-products';

  if (!products || typeof products !== 'object' || !Array.isArray(products) || products.length < 2) {
    return null;
  }

  return (
    <section
      className={`
          ${styles.trendingProducts}
        `}
      aria-labelledby={label}
    >
      <div className={styles.contentWrapper}>
        <h2 id={label} className={styles.title}>
          {t('popular_products')}
        </h2>
        <ul className={styles.productList}>
          {products.map((item, index) => (
            <>
              {item.subbuzz_title && (
                <Product
                  key={item.subbuzz_id}
                  product={item}
                  buzz={buzz}
                  postRef={postRef}
                  index={index}
                />
              )}
            </>
          ))}
        </ul>
      </div>
    </section>
  );
}

function Product({ product = {}, postRef, buzz, index }) {
  const { t } = useTranslation('common');
  const { subbuzz_id, subbuzz_text, rank_in_post } = product;

  useEffect(() => {
    const hasProductSubbuzzHeading = buzz.sub_buzzes.some(
      subbuzz =>
        subbuzz.id === subbuzz_id &&
        subbuzz.bfp_data?.format_name === 'product_subbuzz' &&
        !!subbuzz.bfp_data?.data?.heading
    );
    const hasPopularProductLabel = !!postRef.current.querySelector(
      `[id="${subbuzz_id}"] ~ .trending-product-label`
    );

    if (!hasProductSubbuzzHeading && !hasPopularProductLabel) {
      const anchor = postRef.current.querySelector(`[id="${subbuzz_id}"]`);
      if (anchor && anchor.parentNode) {
        // prettier-ignore
        const subbuzzMediaEl = anchor.parentNode.querySelector(`[class*=media]`);

        if (subbuzzMediaEl) {
          const titleElWrapper = document.createElement('div');
          const titleEl = document.createElement('div');

          titleElWrapper.classList.add('trending-product-label-wrapper');
          titleEl.innerHTML = `${t('popular')}`;
          titleEl.classList.add('trending-product-label');
          titleElWrapper.appendChild(titleEl);

          subbuzzMediaEl.parentNode.insertBefore(
            titleElWrapper,
            subbuzzMediaEl
          );
        }
      }
    }
  }, [subbuzz_id, postRef, t, buzz.sub_buzzes]);

  function onSeeInListClick() {
    let subbuzz;
    const postElement = postRef.current;
    const anchor = postElement.querySelector(`[id="${subbuzz_id}"]`);
    if (anchor) {
      subbuzz = anchor.closest('.subbuzz');
    }
    if (subbuzz) {
      subbuzz.querySelectorAll('a[data-affiliate]').forEach(link => {
        // Rewrite data-origin attr for AmazonSubtagModifier
        link.setAttribute('data-origin', 'tp-subbuzz'),
        setElementSubtags(link, { origin: 'tp-subbuzz' });
      });

      // track internal link click
      trackClientInternalLink(buzz, {
        data_source_name: 'site_component_api',
        item_name: subbuzz_id,
        item_type: 'subbuzz',
        position_in_unit: rank_in_post,
        target_content_id: buzz.id,
        target_content_type: 'buzz',
        unit_name: buzz.id,
        unit_type: 'buzz_body',
      });
    }
  }

  return (
    <li className={styles.trendingProduct}>
      <ProductDescription
        buzz={buzz}
        subbuzz_text={subbuzz_text}
        index={index}
      />
      <div className={styles.seeInList}>
        <a
          className={styles.viewInListBtn}
          href={`#${subbuzz_id}`}
          onClick={onSeeInListClick}
        >
          <span>View in list</span>
          <DownArrow className={styles.downArrow} aria-hidden="true"/>
        </a>
      </div>
    </li>
  );
}

function ProductDescription({ buzz, subbuzz_text, index }) {
  const ref = useAffiliateLinkTracking({ buzz });
  const clickRef = useAmazonSubtagModifier({ buzz });
  const clickButtonPostTapRef = useButtonPostTap({ buzz });
  const clickLinkRef = useLinkTracking(buzz, index);

  useEffect(() => {
    const element = clickRef.current;
    if (element) {
      // truncate description
      truncateHTML(element, isMobile() ? 90 : 150);
      // add tp to subtags
      element.querySelectorAll('a[href]').forEach(link => {
        setElementSubtags(link, { origin: 'tp' });
      });
    }
  });

  return (
    <p
      dangerouslySetInnerHTML={{ __html: subbuzz_text }}
      ref={composeRefs(ref, clickRef, clickLinkRef, clickButtonPostTapRef)}
    />
  );
}

export default withErrorBoundary(withTranslation('common')(TrendingProducts), {
  onError: captureException,
});
