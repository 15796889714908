export const excludedEditions = ['japan'];

export const excludedSkimlinksTags = ['--disable-skimlinks-lib', 'noskimlinks'];

export const excludedCategories = [
  'advertiser',
  'aunews',
  'business',
  'canadanews',
  'community',
  'francenews',
  'japannews',
  'lgbt',
  'politics',
  'quickpost',
  'science',
  'tech',
  'uknews',
  'ukpolitics',
  'usnews',
  'world',
];
