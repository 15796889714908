import { useEffect, useContext } from 'react';
import BuzzContext from '../../../contexts/buzz';
import { buzzCommonLayerData } from '../client-event-tracking';
import { CLUSTER } from '../../../constants';

export default function useNavTracking() {
  const buzz = useContext(BuzzContext);

  useEffect(() => {
    window.clientEventTracking = {
      getPageContextLayer() {
        return buzzCommonLayerData(buzz);
      },
      env: CLUSTER,
    };
  }, [buzz]);

  return () => window.clientEventTracking;
}
