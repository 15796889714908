import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
const inlineScript = `(apscustom = window.apscustom || []).push({ id: "93b7dd52-a8ce-11ed-afa1-0242ac120002:1"});`;

export default function AmazonShoppingRecommendations() {

  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = inlineScript;
    containerRef.current.appendChild(script);
  }, [containerRef]);

  return (
    <div className={styles.amazonAiWrapper}>
      <div ref={containerRef} className="apscustom" id="93b7dd52-a8ce-11ed-afa1-0242ac120002:1"></div>
    </div>
  );
}
