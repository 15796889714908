import { useContext, useEffect, useRef } from 'react';
import { useConsent } from '@buzzfeed/react-components';
import BuzzContext from '../../contexts/buzz';

import { attachClientInternalLinkHandler, attachClientExternalLinkHandler, attachClientImpressionHandler } from './client-event-tracking';

export function useUnitTracking(unitData, external = false) {
  const buzz = useContext(BuzzContext);
  useConsent('tracking');
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return () => {};
    }

    const detachImpressionHandler = attachClientImpressionHandler(element, buzz, unitData);

    const detachUnitTracking = external
      ? attachClientExternalLinkHandler(element, buzz, unitData)
      : attachClientInternalLinkHandler(element, buzz, unitData);

    return () => {
      detachImpressionHandler();
      detachUnitTracking();
    };
  }, [unitData, external]);

  return ref;
}
