/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { safeEmojis, unsafeEmojis } from '@buzzfeed/react-components/lib/constants/emojis';
import Confetti from './Confetti';
import BuzzContext from '../../contexts/buzz';
import { useReactions } from '../../hooks/useReactions';
import SectionTitle from '../SectionTitle';
import { useTranslation } from '../../i18n';
import { isEligible as isActive } from '../Reactions/utils';
import { formatNumberToShorthand } from '../../utils/data';
import { localUserEntries } from '../../utils/user-entries';
import styles from './emojiReactions.module.scss';
const userEmojis = localUserEntries({ namespace: 'localUserEmojis' });

const EmojiReactionItem = ({
  name,
  localEmojiName,
  count = 0,
  onClick = () => {},
  isClicked = false,
}) => {
  const [ showConfetti, setShowConfetti ] = useState(false);

  /**
   * When the confetti animation has completed, trigger the onTransitionEndComplete callback.
   */
  const onConfettiEnd = () => setShowConfetti(false);

  const handleOnClick = () => () => {
    userEmojis.set(localEmojiName);
    onClick(name);
    setShowConfetti(true);
  };

  return (<li className={`${styles.reactionsItem} ${!isClicked && styles.reactionsItemActive}`} key={name}>
    <button
      className={styles.reactionBtn}
      disabled={isClicked}
      onClick={handleOnClick()}
    >
      {name}
    </button>
    {count !== 0 && <span className={styles.count}>{formatNumberToShorthand(count)}</span>}
    {showConfetti &&
      <Confetti className={styles.confetti} emoji={name} onTransitionEndComplete={onConfettiEnd} />
    }
  </li>);
};

EmojiReactionItem.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  localEmojiName: PropTypes.string.isRequired,
};

export const EmojiReactions = ({
  className = '',
  contentId,
  contentType = 'content-object',
  isSponsored,
} = {}) => {
  const { t } = useTranslation('common');
  const [userReaction, setUserReaction] = useState(null);
  // Used in managing the emoji count when user toggles between emoji
  const [previousUserReaction, setPreviousUserReaction] = useState(null);
  const [ emojiReactions, setEmojiReations ] = useState(null);
  const emojisList= [...safeEmojis, ...unsafeEmojis];

  /**
   * If the user has already reacted to this contentType:contentId, display their reaction.
   */
  useEffect(() => {
    const localUserReaction = userEmojis.get(`${contentType}:${contentId}`);

    if (localUserReaction) {
      // Reactions are saved as `{contentType}:{contentId}:{reaction}`. We only need the last
      // element (reaction).
      const reaction = localUserReaction.split(':').pop();

      if ((!isSponsored && emojisList.includes(reaction)) || (isSponsored && safeEmojis.includes(reaction))) {
        setUserReaction(reaction);
      }
    }
  }, [contentId, contentType]);

  const onClickHandler = reaction => {
    if (!previousUserReaction) {
      setPreviousUserReaction(reaction.name);
    }
    reaction.increment();
    // update the count of the clicked emoji
    const updatedReactions = emojiReactions.map(emoji => {
      if (emoji.name === reaction.name) {
        emoji.count++;
      } else if (emoji.name === userReaction) {
        emoji.count--;
      }
      return emoji;
    });
    setEmojiReations(updatedReactions);
    setUserReaction(reaction.name);
  };

  const buzz = useContext(BuzzContext);
  const { reactions, serverError } = useReactions(isActive(buzz), true);

  if (!reactions.length) {
    return null;
  } else if (previousUserReaction && previousUserReaction != userReaction) {
    reactions.forEach(r => {
      if (r.name == previousUserReaction) {
        r.count--;
        setPreviousUserReaction(userReaction);
      }
    });
  }

  /*
    1. filter reactions: exclude unsafe safeEmojis if the content is sponsored
    2. add displayPriority to each reaction, so it can be user for sorting
    3. sort reactions by count (range: 1 - infinite) + displayPriority (range: 1 - 0)
    (in case that two reactions have the same count, the one with higher displayPriority will display first)
  */
  const filteredReactions = [];
    reactions.forEach(emoji => {
    emoji.displayPriority = unsafeEmojis.includes(emoji.name) ?
      1 / (unsafeEmojis.indexOf(emoji.name) + safeEmojis.length + 1) :
      1 / (safeEmojis.indexOf(emoji.name) + 1);

      if (!isSponsored || !unsafeEmojis.includes(emoji.name)) {
        filteredReactions.push(emoji);
    }
  });
  // to avoid sorting reactions instantly on voting, sort them only once - at the beginning
  const filteredAndSortedReactions = filteredReactions.sort((a, b) => (b.count + b.displayPriority) - (a.count + a.displayPriority));
  if (!emojiReactions?.length) {
    setEmojiReations(filteredAndSortedReactions);
  }

  return (
    <section className={`${className} ${styles.reactions}`}>
      <SectionTitle id="reactions-title">{t('what_do_you_think')}</SectionTitle>
      {serverError && <p className={styles.error}>{t('server_error')}</p>}
      <ul
        aria-label="Emoji Reactions"
        aria-controls="dialog"
        className={`${styles.reactionsList} ${reactions.some(reaction => reaction.count > 0) && styles.hasReactions}`}
      >
        {emojiReactions && emojiReactions.map(reaction => (
          <EmojiReactionItem
            key={reaction.name}
            isClicked={userReaction == reaction.name}
            localEmojiName={`${contentType}:${contentId}:${reaction.name}`}
            onClick={() => { onClickHandler(reaction); }}
            {...reaction}
          />
        ))}
      </ul>
    </section>
  );
};

EmojiReactions.propTypes = {
  className: PropTypes.string,
  contentId: PropTypes.string,
  contentType: PropTypes.string,
};

export default EmojiReactions;
