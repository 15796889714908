import { useEffect, useState } from 'react';

// get related products for subbuzz
async function fetchProducts({ productId, setProducts, pageSize }) {
  const url = `https://recsys-api.buzzfeed.com/web/related-product-unit/product?product_id=${productId}&page_size=${pageSize}`;
  try {
    const response = await fetch(url, {});
    const data = await response.json();

    if (data && data.results) {
      setProducts(data.results);
    }
  } catch (error) {
    return error;
  }
  return {};
}

export function useRelatedProducts({
  buzz,
  hasRelatedProductsLasertag,
}) {
  const [products, setProducts] = useState();
  let productId = '';

  for (let i = 0; i < buzz.sub_buzzes.length; i++) {
    const subbuzzID = buzz.sub_buzzes[i].id;
    const mappedProduct = buzz.sub_buzzes[i]?.products;

    if (mappedProduct && !productId) {
      productId = `${mappedProduct[0].id}`;
    }
  }
  
  const pageSize = 10;

  useEffect(() => {
    if (!buzz.id || !buzz.isShopping) {
      return;
    }

    //only make the call if the buzz qualifies
    if (hasRelatedProductsLasertag && productId && !products)
      fetchProducts({ productId, setProducts, pageSize });
  }, [
    buzz,
    productId,
    pageSize,
    hasRelatedProductsLasertag,
  ]);

  return products || [];
}