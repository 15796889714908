/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './confetti.module.scss';

/**
 * The minimum and maximum distance an emoji will travel (pixels)
 */
const MIN_DISTANCE = 10;
const MAX_DISTANCE = 85;

/**
 * The exponent control is used to create a scaling factor for how high an emoji should burst out of
 * the container.
 */
const EXPONENT_CONTROL = 1.5;

/**
 * Generates a random whole number between the specified minimum and maximum values (inclusive).
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} The random number generated.
 */
const randomNumberInRange = (min, max) => {
  if (min > max) {
    [min, max] = [max, min]; // Swap min and max values
  }
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Generates a random degree value between degreeMin and degreeMax for each confetti.
 * By default, the min and max are configured with front facing degrees (between -90 and 90 degrees)
 * so that emoji confetti will travel towards the screen when they burst out.
 * @param {number} count - The number of degrees to generate.
 * @param {number} [options.degreeMax=65] - The maximum degree value.
 * @param {number} [options.degreeMin=-65] - The minimum degree value.
 * @param {number} [options.distributionFactor=1] - The distribution factor for the random degrees,
 * used to create a random distribution that is more evenly spread out, preventing the confetti from
 * clustering together. A value of 1 will create a more uniform distribution. A value of 0 will
 * create a more random distribution.
 * @returns {Array} An array of random degree values.
 */
const generateRandomDegrees = (count, {
  degreeMax = 45,
  degreeMin = -45,
  distributionFactor = 1,
} = {}) => {
  const degrees = [];
  const degreeRange = degreeMax - degreeMin;
  for (let i = 0; i < count; i++) {
    const randomDegree = Math.floor(
      (
        (Math.random() * degreeRange * distributionFactor) + (i * degreeRange / count)
      ) % degreeRange
    ) + degreeMin;
    degrees.push(randomDegree);
  }

  return degrees;
};

/**
 * Component that renders a confetti animation.
 * @param {string} [props.className=''] - Additional CSS class for styling the confetti container.
 * @param {string} props.emoji - The emoji to be used for the confetti.
 * @param {function} [props.onTransitionEndComplete] - Callback function triggered when the confetti
 * animation has completed.
 * @param {Array} [props.range=[10, 15]] - The range of confetti to be rendered.
 */
const Confetti = ({
  className = '',
  emoji = '🤯',
  onTransitionEndComplete,
  range = [10, 15],
}) => {
  /**
   * These values are stored using the useRef hook instead of useState because
   * - The values need to be calculated once and persist throught the lifespan of the component
   *   instance.
   * - The values are not used to trigger a re-render of the component.
   */
  const confettiCount = useRef(randomNumberInRange(...range));
  const degrees = useRef(generateRandomDegrees(confettiCount.current));
  const animationEndIdleCallbackRef = useRef(null);

  const [animateClassName, setAnimateClassName] = useState('');
  const [confettiCounter, setConfettiCounter] = useState(0);

  /**
   * Confetti will not animate if the user has specified that they prefer reduced motion
   * animations. In this case, the onTransitionEndComplete callback is triggered immediately.
   */
  useEffect(() => {
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      setAnimateClassName(styles.animate);
    } else if (typeof onTransitionEndComplete === 'function') {
      onTransitionEndComplete();
    }
  }, [onTransitionEndComplete]);

  /**
   * When the confetti animation has completed, trigger the onTransitionEndComplete callback.
   * This is done using requestIdleCallback to ensure that the callback is only triggered when the
   * browser is idle. This operation is not time critical.
   */
  useEffect(() => {
    if (
      confettiCounter === confettiCount.current && typeof onTransitionEndComplete === 'function'
    ) {
      requestIdleCallback(onTransitionEndComplete);
    }
  }, [confettiCounter, onTransitionEndComplete]);

  // cleanup
  useEffect(() => {
    return () => {
      /**
       * This cleanup function is used to cancel the idle callback when the component is unmounted.
       * It ensures that the handleAnimationEnd callback is not executed if the component is no
       * longer mounted, preventing potential memory leaks.
       */
      if (animationEndIdleCallbackRef.current) {
        cancelIdleCallback(animationEndIdleCallbackRef.current);
      }
    };
  }, []);

  /**
   * A callback for `onAnimationEnd` event, fired on each individual emoji confetti when they
   * complete the falling animation, that will increment the confetti counter. This is done using
   * requestIdleCallback to ensure that the state update is only triggered when the browser is idle.
   * This operation is not time critical.
   * @param {AnimationEvent} event - The animation event.
   */
  const handleAnimationEnd = (event) => {
    animationEndIdleCallbackRef.current = requestIdleCallback(() => {
      if (confettiCounter < confettiCount.current && event.animationName === styles.fadeInOut) {
        setConfettiCounter(prevCounter => prevCounter + 1);
      }
    });
  };

  return (
    <div className={`${className} ${styles.container} ${animateClassName}`} aria-hidden="true">
      <ul className={styles.emojis} onAnimationEnd={handleAnimationEnd}>
        {Array.from({ length: confettiCount.current }).map(
          (_, index) => {
            /**
             * For each confetti, generate a random delay, scale, and distance.
             * - The `randomDelay`` value is kept proportional to the number of confetti, so that
             *   the confetti will burst out all at a similar cadence.
             */
            const randomDelay = randomNumberInRange(25, confettiCount.current * 25);
            const randomScale = randomNumberInRange(25, 100) / 100;
            const randomDistance = randomNumberInRange(MIN_DISTANCE, MAX_DISTANCE);

            /**
             * randomDistance is used in creating a scaling factor for how high an emoji should
             * burst out of the selection. The farther the distance, the taller the
             * arch needs to be.
             */
            const normalizedDistance = randomDistance / MAX_DISTANCE;
            const scalingFactor = Math.pow(1 + normalizedDistance, EXPONENT_CONTROL);

            return (
              <li
                key={index}
                className={styles.emoji}
                style={{
                  '--confetti-rotate': `${degrees.current[index]}deg`,
                  '--confetti-distance-horizontal': `${randomDistance}px`,
                  '--confetti-scaling-factor': scalingFactor,
                  '--confetti-delay': `${randomDelay}ms`,
                  '--confetti-scale': randomScale,
                  // The higher the distance, the higher the z-index. Otherwise, emojis that are
                  // farther back might overlap with emojis that are closer to the screen.
                  zIndex: randomDistance,
                }}
              >
                <div className={styles.verticalLayer}>
                  <div className={styles.scaleLayer}>
                    <div className={styles.opacityLayer}>{emoji}</div>
                  </div>
                </div>
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

Confetti.propTypes = {
  className: PropTypes.string,
  emoji: PropTypes.string.isRequired,
  onTransitionEndComplete: PropTypes.func,
  range: PropTypes.arrayOf(PropTypes.number),
};

export default Confetti;
