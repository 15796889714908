/**
 * Splits number with comma by thousands
 * @param {Number} num - number to be formatted
 * @returns {String} - number with commas
 * eslint-disable max-len
 * @see http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 * eslint-enable max-len
 */

// TODO: add a test for this!

export function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
