
import {
  EXCLUDED_NEWS_BADGES,
  EXCLUDED_NEWS_SECTIONS,
  SUPPORTED_NEWS_COLLECTIONS,
} from '../../../../constants/breadcrumbs';
import { isNews } from '../../../../utils/isNews';
import { destinations } from '../../../../constants';
import { getQuizBreadcrumbs } from './breadcrumbs-quiz';

import { getEdition } from '@buzzfeed/bf-utils/lib/localization';
import * as categoryData from '@buzzfeed/react-components/lib/constants/commerce/category';

let edition = getEdition();
const COMMERCE_CATEGORIES = categoryData[edition] ? categoryData[edition].COMMERCE_CATEGORIES : [];

export function formatShoppingBreadcrumbs(laserTags = [], bcs = []) {
  if (!laserTags.length) return bcs;

  const crumb = COMMERCE_CATEGORIES.find(c => laserTags.includes(c.laser_tag)) || '';
  if (crumb) {
    bcs.push({
      text: crumb.name,
      url: `/shopping/${crumb.id}`,
    });

    if(crumb.children?.length) {
      let crumbSecond = crumb.children.find(c =>
        laserTags.includes(c.laser_tag)
      ) || '';

      if (crumbSecond) {
        bcs.push({
          text: crumbSecond.name,
          url: `/shopping/${crumb.id}/${crumbSecond.id}`,
        });

        if(crumbSecond.children?.length) {
          let crumbThird = crumbSecond.children.find(c =>
            laserTags.includes(c.laser_tag)) || '';

            bcs.push({
              text: crumbThird.name,
              url: `/shopping/${crumb.id}/${crumbSecond.id}/${crumbThird.id}`,
            });
        }
      }
    }
  }

  return bcs;
}

function formatNewsBreadcrumbs(topics) {
  if (!topics || !topics.length) {
    return [];
  }
  return [{ text: topics[0].tag_display_name, url: `/topic/${topics[0].tag_name.replace(/_/g, '-')}` }];
}

const sectionMap = {
  tvandmovies: {
    text: 'TV and Movies',
    url: '/tvandmovies',
  },
  ukpolitics: {
    text: 'Politics',
    url: '/politics',
  },
  longform: {
    text: 'Big Stories',
    url: '/bigstories',
  },
  'as/is': {
    text: 'As/Is',
    url: '/asis',
  },
  lgbt: {
    text: 'LGBTQ',
    url: '/lgbt',
  },
  'internet finds': {
    text: 'Internet Finds',
    url: '/bestoftheinternet',
  },
  'arts & entertainment': {
    text: 'Arts & Entertainment',
    url: '/arts-entertainment',
  },
  culture: {
    text: 'Culture & Criticism',
    url: '/culture',
  },
};

const entertainmentBreadcrumbs = ['uktvandmovies', 'ukcelebrity', 'ukmusic'];

export function getBreadcrumbs(buzz, badges) {
  /**
   * If the buzz is not news post and is a quiz, the breadcrumbs logic will be handled by
   * `getQuizBreadcrumbs` instead. `getQuizBreadcrumbs` returns one set of breadcrumbs in a list.
   * The quiz breadcrumbs we be returned as the `primary` breadcrumbs (`secondary` will be an empty
   * list so that the expectations of this function by other components can remain the same).
   */
  if (buzz.isQuiz && !buzz.isNews) {
    const crumbs = getQuizBreadcrumbs(buzz);
    if (crumbs.length) {
      return { primary: crumbs, secondary: [] };
    }
  }

  const section = buzz.classification.section || buzz.category;
  const additionalSections = buzz.all_classifications.sections;
  const { subbuzz: { commerce_category = [] } = {} } = buzz.laser_tags || {};
  const laserTagNames = commerce_category.map(c => c.tag_name) || [];
  let primary = [];
  let secondary = [];
  let breadcrumbSection = section;
  let url;

  let sectionLowercase = section.toLowerCase();

  if (sectionLowercase === 'buzzfeed') {
    // if a post’s primary section is “BuzzFeed”, we will check to see if there are any Additional Sections
    // (and use the first Additional Section as the label, as long as that first Additional Section isn't "BuzzFeed").
    // if there aren’t, we’ll default to using “Buzz”.
    breadcrumbSection =
      additionalSections && additionalSections[0] !== 'BuzzFeed'
        ? additionalSections[0]
        : 'Buzz';

    sectionLowercase = breadcrumbSection.toLowerCase();
  }
  if (sectionLowercase in sectionMap) {
    const breadcrumb = sectionMap[sectionLowercase];
    breadcrumbSection = breadcrumb.text;
    url = breadcrumb.url;
  } else if (entertainmentBreadcrumbs.includes(sectionLowercase)) {
    breadcrumbSection = 'Entertainment';
    url = '/entertainment';
  }

  primary.push({
    text: breadcrumbSection,
    url:
      url ||
      `/${breadcrumbSection
        .toLowerCase()
        .replace(/&/g, 'and')
        .replace(/ /g, '-')}`,
  });

  if (section === 'Shopping') {
    secondary = [...formatShoppingBreadcrumbs(laserTagNames), ...secondary];
  }

  if (isNews(buzz)) {
    // add the first badge only as secondary
    if (badges.length) {
      const bfoBaseUrl = destinations['buzzfeed'].base_url;
      const badge = badges[0];
      if (!EXCLUDED_NEWS_BADGES.includes(badge.badge_type)) {
        if (badge.type === 'opinion') {
          secondary.push({
            text: 'opinion',
            url: '/collection/opinion',
          });
        } else if (SUPPORTED_NEWS_COLLECTIONS.includes(badge.link_to)) {
          secondary.push({
            text: badge.name,
            url: `/collection/${badge.link_to}`,
          });
        } else {
          secondary.push({
            text: badge.name,
            url: `${bfoBaseUrl}/badge/${badge.link_to || badge.type}`,
          });
        }
      }
    }

    if (!secondary.length) {
      // add tags as secondary
      const topics = buzz.laser_tags?.bf_content_description?.topic;
      if (topics && topics.length) {
        secondary = [...formatNewsBreadcrumbs(topics), ...secondary];
      }
    }

    // filter excluded sections
    primary = primary.filter(
      s => !EXCLUDED_NEWS_SECTIONS.includes(s.text.toLowerCase())
    );

    primary.forEach(primarySection => {
      primarySection.url = `/section${primarySection.url}`;
    });
  }

  return { primary, secondary };
}
