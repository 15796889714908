import {
  QUIZ_CATEGORIES,
  QUIZ_TYPES
} from '../../../../constants';

/**
 * Returns all quiztypes a post has been tagged with.
 * The function checks the buzz's tags against tags of each quiz type. If there are any matches,
 * the quiz type will be included in the list of crumbs returned (it's possible to return more than
 * one).
 * @param {Object} buzz
 * @returns {Array}
 */
function getQuizTypes(buzz) {
  const quizTypes = [];

  for (const [type, { tags = [], title, url }] of Object.entries(QUIZ_TYPES)) {
    if (tags.some(tag => buzz.metadata.tags.includes(tag))) {
      quizTypes.push({ tags, title, type, url });
    }
  }

  return quizTypes;
}

/**
 * Returns all quiz categories associated with the buzz if any.
 * @param {Object} buzz
 * @returns {Array}
 */
function getQuizCategories(buzz) {
  const quizCategories = [];

  QUIZ_CATEGORIES.forEach((category) => {
    const tags = category.feed_tags;

    // Check if there's at least one common tag
    if (tags.some(tag => buzz.metadata.tags.includes(tag))) {
      quizCategories.push(category);
    }
  });
  return quizCategories;
}

export function getQuizBreadcrumbs(buzz, { max = 4 } = {}) {
  const crumbs = [];
  const quizTypes = getQuizTypes(buzz);
  const quizCategories = getQuizCategories(buzz);

  // 1) Any quiz types associated with the buzz will be displayued first in the breadcrumbs.
  quizTypes
    // Some quiz categories are the same as the quiz meta type (ex: personality). Skip this
    // breadcrumb in favor of the quiz category.
    .filter(({ tags }) => !tags.some(
      tag => quizCategories.find(
        ({ feed_tags }) => feed_tags.includes(tag)
      )
    ))
    .forEach(quizType => {
      crumbs.push({
        text: quizType.title,
        url: quizType.url,
      });
    });

  // 2) If the buzz has associated quiz categories, add them as the last bread crumbs.
  quizCategories
    .forEach(quizCategory => {
      crumbs.push({
        text: `${quizCategory.name} Quiz`,
        url: `/quizzes${quizCategory.url}`,
      });
    });

  // Limit the number of breadcrumbs returned.
  crumbs.splice(max);

  return crumbs;
}
