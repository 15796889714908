import React from 'react';
import PropTypes from 'prop-types';
import { formatTimestamp } from '@buzzfeed/bf-utils/lib/timestamp';
import { withTranslation } from '../../../../i18n';
import styles from './headline-timestamp.module.scss';

function isoDateString(ts) {
  const event = new Date(ts * 1000);
  return event.toISOString();
}

function HeadlineTimestamp({
  timestampPublishedUnix,
  timestampUpdatedUnix,
  showUpdatedTimestampOnly,
  countryCode,
  t,
  destination,
}) {
  if (!timestampPublishedUnix || !countryCode) {
    return null;
  }

  const isNewsPage = destination === 'buzzfeed_news';
  const postCreatedIsoString = isoDateString(timestampPublishedUnix);
  const postUpdatedIsoString = isoDateString(timestampUpdatedUnix);
  let timestampCreated;
  let timestampUpdated;
  let postDateCreatedString;
  let postDateUpdatedString;

  if (isNewsPage) {
    if (!showUpdatedTimestampOnly) {
      timestampCreated = formatTimestamp(timestampPublishedUnix, countryCode, {
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
      });
      if (!timestampCreated.hasTimestampString) {
        timestampCreated.formattedTimestamp = timestampCreated.formattedTimestamp
          .replace(' AM', ' am')
          .replace(' PM', ' pm');
      }
    }
    if (timestampUpdatedUnix || showUpdatedTimestampOnly) {
      timestampUpdated = formatTimestamp(
        timestampUpdatedUnix ? timestampUpdatedUnix : timestampPublishedUnix,
        countryCode,
        {
          month: 'long',
          hour: 'numeric',
          minute: 'numeric',
        }
      );
      if (!timestampUpdated.hasTimestampString) {
        timestampUpdated.formattedTimestamp = timestampUpdated.formattedTimestamp
          .replace(' AM', ' am')
          .replace(' PM', ' pm');
      }
    }
  } else if (timestampUpdatedUnix > timestampPublishedUnix) {
    timestampUpdated = formatTimestamp(timestampUpdatedUnix, countryCode);
  } else {
    timestampCreated = formatTimestamp(timestampPublishedUnix, countryCode);
  }

  if (timestampCreated) {
    postDateCreatedString = timestampCreated.hasTimestampString
      ? t('posted')
      : t('posted_on');
  }
  if (timestampUpdated) {
    postDateUpdatedString = timestampUpdated.hasTimestampString
      ? t('updated')
      : t('updated_on');
  }

  return (
    <span className={`${isNewsPage ? styles.timestampNews : styles.timestamp} embed-timestamp`}>
      {!isNewsPage && timestampUpdated && (
        <time dateTime={postUpdatedIsoString}>
          {postDateUpdatedString} {timestampUpdated.formattedTimestamp}
        </time>
      )}
      {!isNewsPage && timestampCreated && (
        <time dateTime={postCreatedIsoString}>
          {postDateCreatedString} {timestampCreated.formattedTimestamp}
        </time>
      )}
      {isNewsPage && <span className={`${styles.timestampNews} embed-timestamp`}>
        {/* For SEO, use updated timestamp in datetime element if avaiable */}
        <time dateTime={timestampUpdated ? postUpdatedIsoString : postCreatedIsoString}>
          {timestampUpdated && <span className={styles.timeDisplay}>{postDateUpdatedString} {timestampUpdated.formattedTimestamp}</span>}
          {timestampCreated && <span className={styles.timeDisplay}>{postDateCreatedString} {timestampCreated.formattedTimestamp}</span>}
        </time>
      </span>}
    </span>
  );
}

HeadlineTimestamp.defaultProps = {
  timestampPublishedUnix: 0,
  timestampUpdatedUnix: 0,
  showUpdatedTimestampOnly: false,
  countryCode: 'en-us',
  destination: 'buzzfeed',
};

HeadlineTimestamp.propTypes = {
  timestampPublishedUnix: PropTypes.number.isRequired,
  timestampUpdatedUnix: PropTypes.number.isRequired,
  showUpdatedTimestampOnly: PropTypes.bool,
  countryCode: PropTypes.string.isRequired,
  destination: PropTypes.string,
};

export default withTranslation('common')(HeadlineTimestamp);
