import { useEffect, useState, useContext } from 'react';
import { Application } from 't3js';
import { i18n } from '../../i18n';
import { formatTimestamp } from '@buzzfeed/bf-utils/lib/timestamp';
import { shareBehavior } from '../../utils/sharing/t3ShareBehavior';
import { ENV, mango_url } from '../../constants';
import DestinationContext from '../../contexts/destination';
import useLegacyTracking from '../analytics/useLegacyTracking';
import BuzzContext from '../../contexts/buzz';
import useABeagleAsync from '../useABeagleAsync';

const formatDate = buzz => ts =>
  formatTimestamp(ts, buzz.language, {
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
  }).formattedTimestamp;

const translate = (key, options = {}) => {
  return i18n.t(key.toLowerCase(), options);
};

class T3App {
  constructor(base_url, abeagle) {
    this.baseUrl = base_url;
    this.abeagle = abeagle;
    this.addBehavior = Application.addBehavior.bind(Application);
    this.addModule = Application.addModule.bind(Application);
    this.broadcast = Application.broadcast.bind(Application);
    this.on = Application.on.bind(Application);
    this.off = Application.off.bind(Application);
    this.start = Application.start.bind(Application);
    this.startAll = Application.startAll.bind(Application);
    this.stop = Application.stop.bind(Application);
    this.getGlobalConfig = Application.getGlobalConfig.bind(Application);
  }

  init(buzz) {
    const config = {
      abeagle: this.abeagle,
      bf_url: this.baseUrl,
      canonical_path: buzz.canonical_path,
      category: buzz.category,
      country: buzz.country_code,
      edition: buzz.classification.edition,
      debug: ENV === 'dev' || ENV === 'stage',
      description: buzz.description,
      formatDate: formatDate(buzz),
      isAd: buzz.isAd,
      language: buzz.language,
      mango_url,
      picture: buzz.picture,
      shareBehavior: 'share-behavior',
      tags: buzz.tags,
      laser_tags: buzz.laser_tags,
      title: buzz.title,
      tracking: this.tracking,
      translate,
    };

    Application.init(config);
    Application.addBehavior('share-behavior', shareBehavior.bind(null, buzz));
  }

  destroy() {
    Application.destroy();
  }

  _setTrackingFns(tracking) {
    this.tracking = tracking;
  }
}

export function useT3() {
  const [t3App, setT3App] = useState(null);
  const buzz = useContext(BuzzContext);
  const { base_url } = useContext(DestinationContext);
  const legacyTracking = useLegacyTracking(buzz);
  const abeagle = useABeagleAsync();

  useEffect(() => {
    // since tracking functions use hooks, get that back as an object
    // and pass through to non-React environment here
    window.BZFD.App = window.BZFD.App || new T3App(base_url, abeagle);
    window.BZFD.App._setTrackingFns(legacyTracking);
    setT3App(window.BZFD.App);
  }, [buzz.id, legacyTracking]);

  return t3App;
}
