import React from 'react';

export const ChevronRight = ({ className, fill = 'none', title='', stroke = '' }) => (
  <svg className={className} fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <title>{title}</title>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 3L10.5 8L5.5 13" stroke={stroke || '#5246f5'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </svg>
);


