const testData = {
  category: 'shopping',
  component_name: 'packages',
  aria_title: 'Shpping MegaSplash',
  countdown: 1582537027,
  cta: {
    text: 'ys See more shopping deals',
    url: 'https://stage.buzzfeed.com/tag/primeday2019',
  },
  data_source: 'curator_api',
  package_id: '5176267',
  results: [
    {
      author: 'maitlandquitmeyer',
      canonical_path: '/maitlandquitmeyer/the-ultimate-guide-to-packing',
      created_at: '2019-10-07T19:16:14Z',
      data_source: 'curator_api',
      description: '::bookmarks this and the Google flights page::',
      id: '5176267',
      image:
        'https://img.buzzfeed.com/buzzfeed-stage/static/2019-10/1/21/tmp/9d6a82acff73/tmp-name-2-1160-1569964871-0_dblbig.jpg',
      image_alt_text: 'Test Alt Text',
      name: '37 Packing Basics You Should Probably Know By Now',
      url:
        'https://stage.buzzfeed.com/maitlandquitmeyer/the-ultimate-guide-to-packing',
    },
    {
      author: 'emilyshwake',
      canonical_path:
        '/emilyshwake/things-from-walmart-thatll-help-cooking-go-faster',
      created_at: '2019-10-18T12:01:07Z',
      data_source: 'curator_api',
      description: "You'll have dinner on the table in an Instant (Pot).",
      id: '5186539',
      image:
        'https://img.buzzfeed.com/buzzfeed-stage/static/2019-10/14/20/campaign_images/0eea6d275cc0/29-things-from-walmart-thatll-help-time-fly-by-in-2-1121-1571085255-0_dblbig.jpg',
      image_alt_text: '',
      name: "29 Things From Walmart That'll Help Cooking Go Faster",
      url:
        'https://stage.buzzfeed.com/emilyshwake/things-from-walmart-thatll-help-cooking-go-faster',
    },
    {
      author: 'marquaysa',
      canonical_path:
        '/marquaysa/amazon-products-that-made-over-1000-people-give-a',
      created_at: '2019-10-17T13:01:08Z',
      data_source: 'curator_api',
      description:
        'From acne products to throwback games to cooking tools, these are the crowd faves.',
      id: '5192014',
      image:
        'https://img.buzzfeed.com/buzzfeed-stage/static/2019-10/11/13/tmp/b43ab1bc39b0/tmp-name-2-1118-1570801958-0_dblbig.jpg',
      image_alt_text: '',
      name:
        '27 Products On Amazon That Inspired Over 1,000 People To Leave A Five Star Review',
      url:
        'https://stage.buzzfeed.com/marquaysa/amazon-products-that-made-over-1000-people-give-a',
    },
    {
      author: 'ryanmac',
      canonical_path: '/ryanmac/unsworth-reply-elon-musk-fucking-idiot',
      created_at: '2019-10-08T05:58:56Z',
      data_source: 'curator_api',
      description: "Musk: 'I\u2019m a fucking idiot.'",
      id: '5189980',
      image:
        'https://img.buzzfeed.com/buzzfeed-stage/static/2019-10/9/5/campaign_images/805012a9d6a3/the-cave-rescuer-suing-elon-musk-claims-the-tesla-2-2951-1570598440-0_dblbig.jpg',
      image_alt_text: '',
      name:
        'The Cave Rescuer Suing Elon Musk Claims The Tesla CEO Fabricated Pedophilia Claims Against Him',
      url:
        'https://stage.buzzfeednews.com/article/ryanmac/unsworth-reply-elon-musk-fucking-idiot',
    },
    {
      author: 'kaylaboyd',
      canonical_path:
        '/kaylaboyd/witchy-products-that-would-probably-make-sabrina-s-3m7z79lu2',
      created_at: '2019-10-16T15:01:08Z',
      data_source: 'curator_api',
      description:
        'Looking for a little bubble, bubble, toil, and trouble? \ud83d\udd2e',
      id: '5191497',
      image:
        'https://img.buzzfeed.com/buzzfeed-stage/static/2019-10/11/20/tmp/43273aea2118/tmp-name-2-1373-1570826863-0_dblbig.jpg',
      image_alt_text: '',
      name:
        '21 Witchy Products That Would Probably Make Sabrina Spellman Jealous',
      url:
        'https://stage.buzzfeed.com/kaylaboyd/witchy-products-that-would-probably-make-sabrina-s-3m7z79lu2',
    },
  ],
  template: 'countdown',
  theme: 'green',
  title: 'Shpping MegaSplash',
  treatments: ['shopping', 'visual', 'large-first-item'],
};

module.exports = testData;
