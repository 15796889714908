export function isEligible(buzz) {
  return !(
    buzz.flags.sensitive ||
    buzz.metavertical === 'news' ||
    buzz.classification.edition === 'Japan' ||
    buzz.classification.edition === 'France' ||
    buzz.tags.includes('--add-yours') ||
    buzz.tags.includes('--post-content-only')
  );
}
