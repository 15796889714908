import React from 'react';
import styles from './shoppingWrapper.module.scss';
import { ShoppingLogo } from './ShoppingLogo';

export const ShoppingWrapper = ({ children }) => {
  return (
    <div>
      <div className={styles.packageWrapper}>
        <div className={styles.heading}>
          <ShoppingLogo />
        </div>
        {children}
      </div>
    </div>
  );
};

export default ShoppingWrapper;
