import { useTranslation } from '../../i18n';

function CommerceSEOLinks({ buzz }) {
  const { t } = useTranslation('common');
  const availableEditions = ['US'];
  const availableLanguages = ['en'];
  let seoLinkType;

  buzz.tags.forEach(function(tag) {
    if (tag.includes('--commerce-seo-links-')) {
      seoLinkType = tag.replace('--commerce-seo-links-', '');
    }
  });

  if (!seoLinkType || seoLinkType === '' || seoLinkType === 'Choose') {
    return null;
  }

  if (
    !availableEditions.some(ae => ae === buzz.classification.edition) ||
    !availableLanguages.some(al => al === buzz.language)
  ) {
    return null;
  }

  return (
    <div className="subbuzz subbuzz-text xs-mb4 xs-relative">
      <h2
        dangerouslySetInnerHTML={{
          __html: t('COMMERCE_SEO_' + seoLinkType.toUpperCase()),
        }}
      />
    </div>
  );
}

export default CommerceSEOLinks;
