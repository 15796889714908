import React, { useContext } from 'react';
import BuzzContext from '../../contexts/buzz';
import LazyLoad from '../LazyLoad';
import { isEligible } from './utils';
import dynamicImport from '../../utils/dynamic-import';

const Reactions = dynamicImport(
  () => import(/* webpackChunkName: "reactions" */ './index.jsx'),
  { ssr: false }
);

export default function ReactionsLazy(props) {
  const buzz = useContext(BuzzContext);

  if (!isEligible(buzz)) {
    return null;
  }

  return (
    <LazyLoad>
      <Reactions {...props} />
    </LazyLoad>
  );
}
