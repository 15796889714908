import { useMemo } from 'react';
import {
  trackClientContentAction,
  trackClientInternalLink,
  trackClientImpression,
} from './client-event-tracking';

/**
 * Returns a set of functions for tracking events
 *
 * Import at the top of the component/file as:
 * const trackingFn = useSubscriptionButtonTracking(buzz, trackingData)
 *
 * Pass the tracking fn to the Subscription Button components via react props
 * eventData will be provided by the component when it fires the event
 *
 * @returns {Function}
 */
export function useSubscriptionButtonTracking(buzz, trackingData = {}) {
  return useMemo(
    () => ({
      internalLink: eventData => {
        trackClientInternalLink(buzz, {
          ...trackingData,
          ...eventData
        });
      },
      contentAction: eventData => {
        trackClientContentAction(buzz, {
          ...trackingData,
          ...eventData
        });
      },
      impression: eventData => {
        trackClientImpression(buzz, {
          ...trackingData,
          ...eventData
        });
      },
    }),
    [buzz, trackingData]
  );
}
