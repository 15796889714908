const downsizeImage = (url, size = 120) => {
  if (!url) {
    return '';
  }
  const delimiter = url.indexOf('?') >= 0 ? '&' : '?';
  const isDownsized = url.includes('downsize');
  return isDownsized ? url : `${url}${delimiter}downsize=${size}:*`;
};

export default downsizeImage;
