import React from 'react';

export const NextArrow = ({ className, style, onClick, isHidden }) => {
  return (
    !isHidden && (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="none"
        >
          <ellipse
            cx="21"
            cy="21"
            rx="21"
            ry="21"
            transform="rotate(-180 21 21)"
            fill="#0F65F0"
          />
          <path
            d="M17.4748 30.7319C17.2036 30.7319 16.9325 30.6234 16.7155 30.4065C16.2817 29.9726 16.2817 29.3218 16.7155 28.888L24.0911 21.5124L16.7155 14.1367C16.2817 13.7029 16.2817 13.0521 16.7155 12.6182C17.1494 12.1844 17.8002 12.1844 18.234 12.6182L27.1282 21.5124L18.234 30.4065C18.0171 30.6234 17.7459 30.7319 17.4748 30.7319Z"
            fill="white"
          />
        </svg>
      </div>
    )
  );
};

export const PrevArrow = ({ className, style, onClick, isHidden }) => {
  return (
    !isHidden && (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="41"
          viewBox="0 0 42 41"
          fill="none"
        >
          <ellipse
            cx="21.0005"
            cy="20.9835"
            rx="21"
            ry="20.0167"
            fill="#0F65F0"
          />
          <path
            d="M24.5257 11.7075C24.7969 11.7075 25.068 11.8109 25.285 12.0177C25.7188 12.4312 25.7188 13.0516 25.285 13.4651L17.9093 20.4954L25.285 27.5256C25.7188 27.9392 25.7188 28.5595 25.285 28.973C24.8511 29.3866 24.2003 29.3866 23.7664 28.973L14.8723 20.4954L23.7665 12.0177C23.9834 11.8109 24.2545 11.7075 24.5257 11.7075Z"
            fill="white"
          />
        </svg>
      </div>
    )
  );
};
