export const CaretRight = ({ className }) => (
  <svg
    className={className}
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3146 4.99927L5.36504 0.0497586L4.12766 1.28714L7.84027 4.99976L4.12766 8.71237L5.36504 9.94975L10.3146 5.00024L10.3141 4.99976L10.3146 4.99927Z"
    />
  </svg>
);
