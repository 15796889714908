import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from '@buzzfeed/react-components';
import { numberWithCommas } from '../../../../utils/numberWithCommas';
import DestinationContext from '../../../../contexts/destination';
import styles from './headline-badges.module.scss';
import { withTranslation } from '../../../../i18n';
import { useUnitTracking } from '../../../../hooks/analytics/useUnitTracking';

function TrendingBadge({ buzzId, t }) {
  const [impressions, setImpressions] = useState(0);
  const { base_url } = useContext(DestinationContext);
  const [
    { error, data },
  ] = useFetch(
    `${base_url}/site-component/v1/en-us/buzzstats?buzz_ids=${buzzId}`,
    { results: [] }
  );

  const unitTrackingData = useMemo(
    () => ({
      eventCategory: 'Buzz:content',
      eventAction: 'trending',
      eventLabel: null,
      unit_type: 'buzz_head',
      unit_name: buzzId,
      item_name: 'trending',
      item_type: 'badge',
      position_in_unit: 0,
      target_content_type: 'feed',
      target_content_id: 'trending',
    }),
    [buzzId]
  );
  const unitEl = useUnitTracking(unitTrackingData);

  useEffect(() => {
    if (!error && data.results[0]) {
      setImpressions(parseInt(data.results[0].total, 10));
    }
  }, [data, error]);

  if (error) {
    return null;
  }

  return (
    <div className={styles.trendingBadgeContainer}>
      <div className={styles.badge}>
        <a ref={unitEl} className={styles.trendingLink} href="/badge/trending">
          <svg
            viewBox="0 0 512 512"
            role="img"
            aria-labelledby="buzz-trending-badge"
          >
            <title id="buzz-trending-badge">{t('trending_badge')}</title>
            <circle cx="250" cy="250" r="250" fill="#E32" />
            <path
              d="M383.7767,251.45855 L367.5385,136.3119 L259.69355,179.82015 L302.4393,204.4977 L262.06035,274.4342 L192.1208,234.05525 L123.2152,353.4048 L161.8099,375.6881 L208.4322,294.93325 L278.37175,335.3122 L341.034,226.781 L383.7767,251.45855 Z"
              fill="#FFF"
            />
          </svg>
        </a>
      </div>
      <div className={styles.trendingText}>
        <div className={styles.trendingTitle}>{t('trending')}</div>
        {impressions >= 50000 && (
          <div
            className={`${styles.trendingTitle} ${styles.trendingViewCount}`}
          >
            {numberWithCommas(impressions)} {t('views')}
          </div>
        )}
      </div>
    </div>
  );
}

TrendingBadge.propTypes = {
  buzzId: PropTypes.string.isRequired,
};

export default withTranslation('common')(TrendingBadge);
