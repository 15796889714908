import cookies from '@buzzfeed/bf-utils/lib/cookies';

export const DEFAULT_AUTH_COOKIE = 'bf2-b_info';

export function getUserInfo(userInfo) {
  if (!userInfo) {
    return null;
  }

  const attribs = decodeURIComponent(userInfo).split('&');

  // handle special case of image with crop data
  const imageIdx = attribs.findIndex(v => v.match('^image='));
  if (
    imageIdx !== -1 &&
    attribs[imageIdx + 1] &&
    attribs[imageIdx + 1].match('^crop=')
  ) {
    const cropData = attribs.splice(imageIdx + 1, 1);
    attribs[imageIdx] += '&' + cropData;
  }

  return attribs.reduce((infoObj, chunk) => {
    // value of an info key can contain '=' symbol,
    // which is also a key/value pair delimiter

    // so we are splitting a chunk (key/value pair) and use the first value as a key
    const [key, ...valueParts] = chunk.split('=');

    // then join the value parts with '=' again
    const value = valueParts.join('=');

    infoObj[key] = decodeURIComponent(value);
    return infoObj;
  }, {});
}

export function getUserCookieInfo() {
  return getUserInfo(cookies.get(DEFAULT_AUTH_COOKIE));
}
