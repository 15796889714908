import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useRef,
  useLayoutEffect,
} from 'react';
import Slider from 'react-slick';
import styles from './styles.module.scss';
import { Products } from '@buzzfeed/react-components';
import { useUnitTracking } from '../../hooks/analytics/useUnitTracking';
import { attachClientImpressionHandler } from '../../hooks/analytics/client-event-tracking';
import composeRefs from '@seznam/compose-react-refs';
import { useAmazonSubtagModifier } from '../../hooks/useAmazonSubtagModifier';
import { useSkimlinksXcustModifier } from '../../hooks/useSkimlinksXcustModifier';
import { useUserId } from '../../hooks/useUserId';
import { recsys_api_origin } from '../../constants';
import DestinationContext from '../../contexts/destination';
import AdUnit from '../Ads/AdUnit';
import CrystalBall from '../../public/static/images/PersonalizedSeeMore/crystal_ball.png';
import { NextArrow, PrevArrow } from './arrows';

const MOBILE_BREAKPOINT = 831;
const MIN_PRODUCTS = 3;

export default function PersonalizedSeeMore({ buzz = {}, version, ...props }) {
  const isPersonalized = version === 'var1';
  const userId = useUserId();
  const { base_url } = useContext(DestinationContext);
  const personalizedApiUrl = `${recsys_api_origin}/users-personalized-products?client_uuid=${userId}`;
  const trendingApiUrl = `${base_url}/site-component/v1/en-us/trending-products?convert_links=true&`;

  const [products, setProducts] = useState([]);
  const [headerTitle, setHeaderTitle] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_BREAKPOINT);

  const PSMElement = useRef(null);
  const clickAmazonRef = useAmazonSubtagModifier({ buzz });
  const clickSkimlinksRef = useSkimlinksXcustModifier({ buzz });

  const commonTrackingData = {
    item_name: 'product',
    item_type: 'card',
    position_in_unit: 1,
    unit_type: 'buzz_body',
    unit_name: buzz.id,
    subunit_type: 'package',
    subunit_name: 'personalized_see_more',
    target_content_type: 'subbuzz',
  };

  const unitTrackingData = useMemo(() => ({ ...commonTrackingData }), [buzz.id]);

  // Attach impression handler
  useLayoutEffect(() => {
    if (PSMElement.current) {
      attachClientImpressionHandler(PSMElement.current, buzz, commonTrackingData);
    }
  }, [PSMElement]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch products data
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      const apiUrl = isPersonalized ? personalizedApiUrl : trendingApiUrl;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        const items = isPersonalized ? data?.results : data?.items;
        const randomizedItems = items?.sort(() => 0.5 - Math.random()).slice(0, MIN_PRODUCTS) ?? [];
        setProducts(randomizedItems);
      } catch (error) {
        console.error('Error fetching products:', error);
        setProducts([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) fetchProducts();
  }, [userId, isPersonalized, personalizedApiUrl, trendingApiUrl]);

  // Fetch header title
  useEffect(() => {
    const fetchHeaderTitle = async () => {
      if (!isPersonalized) {
        setHeaderTitle('Other products our readers are loving');
        return;
      }

      try {
        const response = await fetch(`${recsys_api_origin}/users-top-product-category?client_uuid=${userId}`);
        const data = await response.json();
        const extractedString = data?.[0]?.[0]?.replace(/^:/, '').trim() ?? '';
        setHeaderTitle(extractedString ? `Because you like ${extractedString}` : 'Just Picked for you!');
      } catch {
        setHeaderTitle('Just Picked for you!');
      }
    };

    fetchHeaderTitle();
  }, [isPersonalized, userId]);

  if (isLoading) return null;
  if (products.length < MIN_PRODUCTS) return <AdUnit {...props} />;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow isHidden={currentSlide === 2} />,
    prevArrow: <PrevArrow isHidden={currentSlide === 0} />,
    centerMode: true,
    centerPadding: '50px',
    beforeChange: (_, next) => setCurrentSlide(next),
  };

  const productProps = {
    useUnitTracking,
    tracking: unitTrackingData,
    className: styles.productsWrapper,
    productClassName: styles.product,
    showButton: true,
    trackingTag: 'stockupeverydayessentialsbcp-20',
  };

  return (
    <div className={styles.component} ref={PSMElement}>
      <div className={styles.header}>
        <div className={styles.header__title}>{headerTitle}</div>
        <div className={styles.header__subtitle}>
          {isPersonalized ? 'Based on what you\'ve seen, here are some suggestions:' : 'Popular products you might want to check out:'}
        </div>
        {!isMobile && (
          <div className={styles.header__crystal_ball}>
            <img src={CrystalBall} alt="Crystal ball" />
          </div>
        )}
      </div>
      <div className={styles.content} ref={composeRefs(clickAmazonRef, clickSkimlinksRef)}>
        {isMobile ? (
          <>
            {currentSlide !== 0 && <div className={styles.gradientOverlayLeft} />}
            {currentSlide !== 2 && <div className={styles.gradientOverlayRight} />}
            <Slider {...settings}>
              {products.map((product, index) => (
                <div key={index} className={styles.carouselSlide}>
                  <Products products={[product]} len={1} {...productProps} />
                </div>
              ))}
            </Slider>
          </>
        ) : (
          <Products products={products} len={MIN_PRODUCTS} {...productProps} />
        )}
      </div>
    </div>
  );
}
