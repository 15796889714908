import React, { useContext, useEffect, useState } from 'react';
import ABeagleContext from '../../contexts/abeagle';
import BuzzContext from '../../contexts/buzz';
import { admiralScript } from '@buzzfeed/bf-utils';



function AdmiralScript() {
  const buzz = useContext(BuzzContext);
  const { getFeatureFlagValue } = useContext(ABeagleContext);
  const isEnabled = getFeatureFlagValue('RT-1042-Admiral-script-on-BFDC') || getFeatureFlagValue('RT-1042-Admiral-script-on-BFN');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isEnabled && !isLoaded) {
      setIsLoaded(true);
      admiralScript.init(buzz.destination_name);
    }
  }, [isEnabled]);

  return (
    <></>
  );
}

export default AdmiralScript;
