import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BuzzContext from '../../../contexts/buzz';
import layoutStyles from './taboola.module.scss';
import { api } from '@buzzfeed/consent';

const getAttributes = (isMobile = false, placement = 1) => {
  const prefix1 = isMobile ? 'sp' : 'desktop';
  const prefix2 = isMobile ? 'SP' : 'Desktop';
  const id = `taboola-below-article-thumbnails-${prefix1}${placement}`;
  const mode = placement == 1 ? 'thumbnails-a' : 'alternating-thumbnails-a';

  const inline_js = `
    window._taboola = window._taboola || [];
    if (publishedDate > firstDecDate) {
      // MG code
      _taboola.push({
        mode: '${mode}',
        container: '${id}',
        placement: 'Below Article Thumbnails ${prefix2}${placement}',
        target_type: 'mix'
      });
    } else {
      //Rev share code
      _taboola.push({
        mode: '${mode}',
        container: '${id}',
        placement: 'Below Article Thumbnails ${prefix2}${placement} Past',
        target_type: 'mix'
      });
    }
  `;
  return { id, inline_js }
};

const inline_start_body_js = `
  var docString = document.documentElement.innerHTML.toString();
  var arrPublished = docString.split("datePublished");
  var publishedDateString = arrPublished[1].split('"')[2];
  var arrModified = docString.split("dateModified");
  var modifiedDateString = arrModified[1].split('"')[2];
  if(modifiedDateString){
    // replacing publishedDate if dateModified has value
    publishedDateString = modifiedDateString;
  }
  var publishedDate = new Date(publishedDateString);
  var firstDecDate = new Date("12/01/2022");
`;

const inline_end_body_js = `
  window._taboola = window._taboola || [];
  _taboola.push({flush: true});
`;

const inline_head_js = `
  window._taboola = window._taboola || [];
  _taboola.push({article:'auto'});
  !function (e, f, u, i) {
    if (!document.getElementById(i)){
      e.async = 1;
      e.src = u;
      e.id = i;
      f.parentNode.insertBefore(e, f);
    }
  }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/buzzfeedjapan/loader.js',
  'tb_loader_script');
  if(window.performance && typeof window.performance.mark == 'function')
    {window.performance.mark('tbl_ic');}
`;

function TaboolaJP({
  isMobile,
  placement = 1
}) {
  const [userConsentCompleted, setUserConsentCompleted] = useState();
  const [listenerOn, setListenerOn] = useState();
  const [id, setId] = useState();
  const [inlineJS, setInlineJS] = useState();

  const { classification, flags, isPreview, tags } = useContext(BuzzContext);

  useEffect(() => {
    const { id, inline_js } = getAttributes(isMobile, placement);
    setId(id);
    setInlineJS(inline_js);
  },[isMobile]);

  const isEnabled =
    !flags.sensitive &&
    !flags.nsfw &&
    !flags.ad &&
    !tags.includes('--noads') &&
    !isPreview &&
    classification.edition === 'Japan';

  useEffect(() => {
    api.getTCData()
        .then(({ tcData }) => {
          const { gdprApplies, eventStatus } = tcData;
          if (
            (!gdprApplies || ['useractioncomplete', 'tcloaded'].includes(eventStatus)) &&
            !document.getElementById('tb_loader_script')
          ) {
            const headScript = document.createElement('script');
            headScript.type = 'text/javascript';
            headScript.innerHTML = inline_head_js;
            document.head.appendChild(headScript);

            const bodyScript = document.createElement('script');
            bodyScript.type = 'text/javascript';
            bodyScript.innerHTML = inline_end_body_js;
            document.body.appendChild(bodyScript);

            if (!userConsentCompleted) {
              setUserConsentCompleted(true);
            }
          } else if (!listenerOn && !userConsentCompleted) {
            api.setTCFListener(({ tcData }) => {
              setUserConsentCompleted(tcData.eventStatus === "useractioncomplete" || tcData.eventStatus === 'tcloaded');
            });
            setListenerOn(true);
          }
        });
  }, [listenerOn, userConsentCompleted]);

  return (isEnabled &&
    <div className={layoutStyles.taboolaWrapper}>
      {placement == 1 && <script type="text/javascript" dangerouslySetInnerHTML={{ __html: inline_start_body_js }} />}
      <div id={id}></div>
      <script type="text/javascript" dangerouslySetInnerHTML={{ __html: inlineJS }} />
    </div>
  );
}

TaboolaJP.propTypes = {
  isMobile: PropTypes.bool,
  placement: PropTypes.number,
};

export default TaboolaJP;
