import React, { useMemo } from 'react';
import { useUnitTracking } from '../../hooks/analytics/useShoppingUnitTracking';
import styles from './relatedProducts.module.scss';

const GRID = 'product_grid';
const CAROUSEL = 'product_carousel';

export const ProductCard = ({
  item,
  index,
  variant,
  trackingLayer,
  parentSubbuzzIndex,
}) => {
  const cardTracking = useMemo(
    () => ({
      ...trackingLayer,
      item_type: 'card',
      item_name: item.id,
      position_in_unit: parentSubbuzzIndex,
      position_in_subunit: index,
      target_content_type: 'subbuzz',
      target_content_id: item.subbuzz_id,
    }),
    [item, index, trackingLayer, parentSubbuzzIndex]
  );

  const clickLinkRef = useUnitTracking(cardTracking);

  let variantNumber = 1;
  switch (variant) {
    case GRID:
      variantNumber = 2;
      break;
    case CAROUSEL:
      variantNumber = 3;
      break;
    default:
      variantNumber = 1;
  }
  const subbuzUrlWithOrigin = `/${item.buzz_url}?origin=1125-${variantNumber}#${item.subbuzz_id}`;
  
  const getFormattedDescription = (description) => {
    const hasEndingPeriod = description.charAt(description.length - 1) === '.';

    let descriptionWithAdjustments = hasEndingPeriod
      ? description
          .slice(0, -1) //remove period at end of string
          .replace(/<a/g, '<p')
          .replace(/a>/g, 'p>')
      : description.replace(/<a/g, '<p').replace(/a>/g, 'p>'); //swap <a> with <p>

    return descriptionWithAdjustments;
  };

  return (
    <a
      className={variant === GRID ? styles.gridItem : styles.carouselItem}
      href={subbuzUrlWithOrigin}
      ref={clickLinkRef}
      target="_blank"
      rel="noreferrer"
    >
      <div
        ref={clickLinkRef}
        className={
          variant === GRID
            ? styles.gridImageWrapper
            : styles.carouselImageWrapper
        }
      >
        <img
          src={`${item.product_image}?fill=176:176;center,top`}
          alt=""
          className={styles.image}
        />
      </div>
      <div
        className={
          variant === GRID ? styles.gridTextWrapper : styles.carouselTextWrapper
        }
      >
        <div
          className={`${styles.titleText} ${styles.descriptionTitleTest}`}
          dangerouslySetInnerHTML={{
            __html: getFormattedDescription(item.description_markdown),
          }}
        />
        <div className={styles.infoWrapper}>
          <div className="bold xs-text-4 xs-mt2 text-gray">
            {item.product_price}
          </div>
          <div className="xs-text-6 text-gray-lightest ">
            on {item.retailer}
          </div>
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
