import React from 'react';

export const ShoppingLogo = ({ className }) => (
  <svg
    role="img"
    aria-label="BuzzFeed Shopping logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 535.7 153.8"
    className={className}
  >
    <title>Shopping on BuzzFeed</title>
    <g>
      <path
        d="M85.4 33v-9.5C85.4 9.6 72.9.4 57.6 2.8S29.7 18.6 29.7 32.5v11.7"
        fill="none"
        stroke="#000"
        strokeWidth={4.92}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={4.92}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.5 51.2L11.8 56.4 21.1 47.5 30.4 52.7 39.7 43.8 49 49 58.3 40 67.6 45.3 76.9 36.3 86.2 41.6 95.6 32.7 102.7 37.9 102.7 144.1 3.6 135.6z"
      />
      <path
        fill="#f37a7b"
        stroke="#000"
        strokeWidth={4.92}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M129.2 38.9L121.7 43.5 118.8 35.9 112.6 40.8 108.8 32.7 102.7 37.4 102.7 144.1 129.2 134z"
      />
      <path
        id="Arrow"
        d="M85.4 90.4L81.1 60.1 52.7 71.6 64 78.1 53.3 96.5 35 85.9 16.8 117.3 27 123.1 39.2 101.9 57.6 112.5 74.1 83.9z"
      />
      <path d="M155.6 80.9V38.1h19c7.4 0 12 4.5 12 10.9 0 5.4-3.6 9-7.5 9.8 4.6.7 8.3 5.4 8.3 10.5 0 6.9-4.6 11.5-12.4 11.5h-19.4zm25.5-31.1c0-3.8-2.6-6.9-7.4-6.9h-12.8v13.8h12.8c4.8-.1 7.4-3 7.4-6.9zm.8 18.9c0-3.9-2.7-7.4-8-7.4h-13.1V76H174c5 .1 7.9-2.7 7.9-7.3zM204.8 64.3V38.1h5.3v26c0 7.8 4.2 12.8 12.1 12.8 7.9 0 12.1-4.9 12.1-12.8v-26h5.3v26.1c0 10.6-5.8 17.4-17.4 17.4-11.6 0-17.4-6.8-17.4-17.3zM256.7 80.9v-4.4l24-33.6h-24v-4.7h30.8v4.4l-24 33.6H288v4.7h-31.3zM303.1 80.9v-4.4l24-33.6h-24v-4.7h30.8v4.4l-24 33.6h24.5v4.7h-31.3zM351.4 80.9V38.1h28v4.7h-22.7v13.8H379v4.7h-22.2v19.5h-5.4zM395.6 80.9V38.1h28v4.7h-22.7v13.8h22.2v4.7h-22.2V76h22.7v4.7h-28zM441 80.9V38.1h28v4.7h-22.7v13.8h22.2v4.7h-22.2V76H469v4.7h-28zM486.4 80.9V38.1H501c13.3 0 21.9 9.3 21.9 21.4 0 12.2-8.7 21.4-21.9 21.4h-14.6zm31-21.4c0-9.2-5.8-16.7-16.4-16.7h-9.3v33.3h9.3c10.4 0 16.4-7.4 16.4-16.6z" />
      <path d="M154.5 129.3l4.9-6.9c3 3.1 7.7 5.8 13.6 5.8 5 0 7.4-2.3 7.4-4.7 0-7.6-24.6-2.4-24.6-18.5 0-7.1 6.2-13 16.2-13 6.8 0 12.4 2.1 16.7 6l-5.1 6.7c-3.5-3.2-8.1-4.7-12.4-4.7-3.9 0-6.1 1.7-6.1 4.3 0 6.8 24.6 2.2 24.6 18.2 0 7.8-5.6 13.7-17.1 13.7-8.3-.1-14.2-2.8-18.1-6.9zM232.2 135.4v-18H212v18h-9.1V92.6h9.1v16.8h20.2V92.6h9.2v42.8h-9.2zM254.7 114c0-12.9 9.4-22.1 22.3-22.1 13 0 22.4 9.2 22.4 22.1 0 12.9-9.4 22.1-22.4 22.1-12.8 0-22.3-9.2-22.3-22.1zm35.4 0c0-8-5.1-14-13-14s-13 6-13 14 5.1 14 13 14 13-6 13-14zM312.9 135.4V92.6h20c9.3 0 14.4 6.3 14.4 13.8 0 7.4-5.1 13.8-14.4 13.8H322v15.2h-9.1zm25.1-29c0-3.6-2.7-5.8-6.3-5.8H322v11.5h9.7c3.6.1 6.3-2.2 6.3-5.7zM360 135.4V92.6h20c9.3 0 14.4 6.3 14.4 13.8 0 7.4-5.1 13.8-14.4 13.8h-10.9v15.2H360zm25-29c0-3.6-2.7-5.8-6.3-5.8H369v11.5h9.7c3.6.1 6.3-2.2 6.3-5.7zM407 135.4V92.6h9.1v42.8H407zM461.1 135.4l-20.4-27.9v27.9h-9.1V92.6h9.4l19.8 26.9V92.6h9.1v42.8h-8.8zM483.4 114c0-13.5 10.3-22.1 22.8-22.1 8.9 0 14.5 4.5 17.7 9.6l-7.6 4.1c-1.9-3-5.6-5.6-10.1-5.6-7.8 0-13.4 6-13.4 14 0 8.1 5.6 14 13.4 14 3.8 0 7.4-1.7 9.1-3.3v-5.1H504v-8h20.4V128c-4.4 4.9-10.5 8.1-18.2 8.1-12.5.1-22.8-8.6-22.8-22.1z" />
    </g>
  </svg>
);
