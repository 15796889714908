import React, { useContext } from 'react';
import BuzzContext from '../../contexts/buzz';
import { trackClientInternalLink } from '../../hooks/analytics/client-event-tracking';
import styles from './quiz-party-promo.module.scss';
import { urlToId } from '../../utils/url';

const PROMO_DATA = {
  descText: 'Take this quiz with friends in real time and compare results',
  triviaDescText:
    'Can you beat your friends at this quiz? Challenge them to a trivia party!',
  btnText: 'Check it out!',
  utmCampaign: 'bpage_promo_v2',
};

export const QuizPartyLogo = ({ quizTrivia }) =>
  quizTrivia ? (
    <div className={styles.triviaLogo}>&nbsp;</div>
  ) : (
    <div className={styles.logo}>&nbsp;</div>
  );

export const QuizPartyDesc = ({ quizTrivia }) => {
  const { descText, triviaDescText } = PROMO_DATA;

  return quizTrivia ? (
    <p className={styles.triviaDesc}>{triviaDescText}</p>
  ) : (
    <p>{descText}</p>
  );
};

function QuizPartyPromo() {
  const buzz = useContext(BuzzContext);
  const quizTrivia = buzz.sub_buzzes?.[0]?.bfp_data?.data?.type === 'trivia';

  // check compatibility of renderer
  const renderTags = buzz.laser_tags?.bf_post_properties?.quiz_render || [];
  const isCompatibleRender = renderTags.find(
    tag => tag.tag_name === 'bfp_quiz'
  );

  // only show on english language posts
  if (buzz.language !== 'en') {
    return null;
  }

  // should be shown on ad posts only if it has a special 'quiz_party' Laser Tag
  const isAdvertiserWithQuizpartyTag =
    buzz.editorial_status === 'Advertising' &&
    buzz.laser_tags?.bf_content_description?.frames_and_formats?.some(
      (tag) => tag.tag_name === 'quiz_party'
    );

  const isCompatibleEditorialStatus =
    ['Moderated Community', 'Editorial'].includes(buzz.editorial_status) ||
    isAdvertiserWithQuizpartyTag;

  if (!isCompatibleRender || !isCompatibleEditorialStatus) {
    return null;
  }

  const { btnText, utmCampaign } = PROMO_DATA;

  // these params are used in Quiz Party GA to track referrals
  const urlParams = [
    `qurl=${buzz.canonical_path}`,
    'utm_medium=referral',
    'utm_source=buzzfeed',
    `utm_campaign=${utmCampaign}`,
  ];
  const quizPartyLink = `/quizparty?${urlParams.join('&')}`;

  const trackClick = () => {
    // https://github.com/buzzfeed/mono/blob/master/data_registry_api/datatypes/client_tracking_events/web_content_actions_bf.yml
    trackClientInternalLink(buzz, {
      target_content_id: urlToId(quizPartyLink),
      target_content_type: 'url',
      item_name: 'cta',
      item_type: 'button',
      position_in_subunit: 0,
      position_in_unit: 0,
      subunit_name: 'quizparty_promo',
      subunit_type: 'component',
      unit_name: buzz.id,
      unit_type: 'buzz_head',
    });
  };

  return (
    <div className={styles.quizPartyPromo}>
      <div className={styles.title}>BuzzFeed Quiz Party!</div>
      <QuizPartyLogo quizTrivia={quizTrivia} />
      <QuizPartyDesc quizTrivia={quizTrivia} />
      <a
        className={styles.quizPartyLink}
        href={quizPartyLink}
        onClick={trackClick}
      >
        {btnText}
      </a>
    </div>
  );
}

export default QuizPartyPromo;
