import { useMemo } from 'react';
import {
  trackClientContentAction,
  trackClientExternalLink,
} from './client-event-tracking';

/**
 * Returns a set of functions for tracking events
 *
 * Import at the top of the component/file as:
 * const trackingFn = useSignInTracking(buzz, trackingData)
 *
 * Pass the tracking fn to the Sign In Modal components via react props
 * eventData will be provided by the component when it fires the event
 *
 * @returns {Function}
 */
export function useSignInTracking(buzz, trackingData = {}) {
  return useMemo(
    () => ({
      externalLink: eventData => {
        trackClientExternalLink(buzz, {
          ...trackingData,
          ...eventData
        });
      },
      contentAction: eventData => {
        trackClientContentAction(buzz, {
          ...trackingData,
          ...eventData
        });
      },
    }),
    [buzz, trackingData]
  );
}
