export const formatNumberToShorthand = (num, dec = 1) => {
  if (!num) {
    return '0';
  }

  const abbreviations = { billion: 'B', million: 'M', thousand: 'K' };

  if (num >= 1e9) return (num / 1e9).toFixed(dec).replace(/\.0+$/, '') + abbreviations.billion;
  if (num >= 1e6) return (num / 1e6).toFixed(dec).replace(/\.0+$/, '') + abbreviations.million;
  if (num >= 1e3) return (num / 1e3).toFixed(dec).replace(/\.0+$/, '') + abbreviations.thousand;

  return num.toString();
};
