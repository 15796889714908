import React, { useContext } from 'react';
import BuzzContext from '../../contexts/buzz';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import { isNews } from '../../utils/isNews';
import { CLUSTER } from '../../constants'

function PubFeed() {
  const buzz = useContext(BuzzContext);
  const adsContext = useContext(AdsContext);
  const userCountry = adsContext?.env?.userCountry;
  const showUnit = !isNews(buzz) && buzz.classification.edition !== 'Japan' && !buzz.isAd && (userCountry === 'au' || userCountry === 'nz');
  const isProd = CLUSTER !== 'dev' && CLUSTER !== 'stage'

  const PUBFEED_HEADER_SCRIPT = `
    window.PubFeedHeader = true;
    var script = document.createElement('script');
    script.src = "https://${isProd ? 'pubfeed' : 'staging-pubfeed'}.linkby.com/widget.js";
    document.getElementsByTagName('head')[0].appendChild(script);
  `;

  if (!showUnit) {
    return '';
  }

  if (showUnit && !window['PubFeedHeader']) {
    const headerScript = document.createElement('script');
    headerScript.type = 'text/javascript';
    headerScript.innerHTML = PUBFEED_HEADER_SCRIPT;
    document.head.appendChild(headerScript);
  }

  return (
    <>
      <div className="linkby-widget" data-type="listicle"></div>
    </>
  );
}

export default PubFeed;
