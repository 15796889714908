import { useContext, useEffect, useRef } from 'react';
import { getElementData } from '@buzzfeed/bf-utils/lib/affiliate-link';
import { isInternalUrl } from '@buzzfeed/bf-utils/lib/internal-url';
import { isNews } from '../../utils/isNews';
import { cleanLink } from './utils';
import {
  trackClientExternalLink,
  trackClientInternalLink,
} from './client-event-tracking';
import DestinationContext from '../../contexts/destination';

export function useLinkTracking(buzz, index) {
  const ref = useRef(null);
  const { destination, base_url } = useContext(DestinationContext);
  const baseUrl = new URL(base_url).hostname;

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }
    const isNewsRelatedLinks = Boolean(
      isNews(buzz) && element.querySelector('div.subbuzz-bfp--related_links')
    );

    element.querySelectorAll('a[href]').forEach(link => {
      link.addEventListener('click', () => {
        const data = getElementData(link);
        const anchor = element.querySelector('div.subbuzz-anchor');
        const subbuzzId = anchor && anchor.id ? anchor.id : null;

        const webContentData = {
          position_in_unit: Number(index),
          unit_type: 'buzz_body',
          unit_name: buzz.id,
          item_type: isNewsRelatedLinks ? 'card' : 'subbuzz',
          item_name: subbuzzId,
          subunit_name: isNewsRelatedLinks ? 'more_on_this' : '',
          subunit_type: isNewsRelatedLinks ? 'package' : '',
          link_id: data['link-id'],
        };

        if (isInternalUrl(data.href, baseUrl)) {
          trackClientInternalLink(buzz, {
            ...webContentData,
            target_content_type: 'url',
            target_content_id: data.href,
          });
        } else {
          trackClientExternalLink(buzz, {
            ...webContentData,
            target_content_url: data.href,
          });
        }
      });
    });
  }, [buzz, destination, index]);

  return ref;
}
